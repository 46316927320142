/**
 * Generates the color tokens for the given variant.
 * The tokens are provided for each interactive state.
 * @param variant - The link variant value.
 * @returns The color tokens for the given variant.
 */
export const getColorTokensForVariant = variant => {
  switch (variant) {
    case 'action':
      return {
        idle: 'text-interactive-default',
        hover: 'text-interactive-default-hover',
        active: 'text-interactive-default-pressed',
        disabled: 'text-interactive-default-disabled'
      };
    case 'body':
      return {
        idle: 'text-primary-default',
        hover: 'text-primary-default',
        active: 'text-primary-default',
        disabled: 'text-interactive-default-disabled'
      };
    case 'danger':
      return {
        idle: 'text-alert-default',
        hover: 'text-alert-hover',
        active: 'text-alert-pressed',
        disabled: 'text-interactive-default-disabled'
      };
    case 'inverse':
      return {
        idle: 'text-interactive-on-fill-default',
        hover: 'text-interactive-default-pressed',
        active: 'fill-tertiary-default',
        disabled: 'text-interactive-default-disabled'
      };
    default:
      {
        const __exhaustiveCheck = variant;
        throw new Error(`Unhandled \`variant\` argument: ${variant}`);
      }
  }
};

/**
 * Checks if the given URL is attempting to run JavaScript code (other than no-op code).
 * This function is used to prevent XSS attacks.
 * @param url - The URL to check.
 * @returns A boolean indicating if the URL is safe.
 */
export const getIsSafeUrl = url => {
  if (url === undefined) {
    return true;
  }

  // eslint-disable-next-line no-script-url
  const noopInjectedJavaScript = 'javascript:void(0)';

  // Matches the protocol part of an absolute URL, e.g. "http:"
  const protocolRegex = /^([^:]+):/;

  // Whitespace and control characters are ignored, so this strips them out
  // eslint-disable-next-line no-control-regex
  const IGNORED_PROTOCOL_CHARS_REGEX = /[\s\x00-\x1f]/g;
  const matchesProtocol = url.match(protocolRegex);
  if (!matchesProtocol || url === noopInjectedJavaScript) {
    return true;
  }
  const isInjectedJavaScript = matchesProtocol[0].replace(IGNORED_PROTOCOL_CHARS_REGEX, '')
  // eslint-disable-next-line no-script-url
  .toLowerCase() === 'javascript:';
  return !isInjectedJavaScript;
};