"use es6";

import { getMixer } from "./complex";
import { mixNumber } from "./number";
function mix(from, to, p) {
  if (typeof from === "number" && typeof to === "number" && typeof p === "number") {
    return mixNumber(from, to, p);
  }
  const mixer = getMixer(from);
  return mixer(from, to);
}
export { mix };