import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as core from 'hub-http/middlewares/core';
import * as hubapi from 'hub-http/middlewares/hubapi';
// @ts-expect-error no types
import * as debug from 'hub-http/middlewares/debug';
// @ts-expect-error no types
import * as user from 'hub-http/middlewares/user';
import { stringify } from 'hub-http/helpers/params';
const client = promiseClient(createStack(core.services, hubapi.defaults, user.recyclePromise, debug.allowTimeoutOverride, user.portalIdBody,
// @ts-expect-error migration error
core.bodyType('application/x-www-form-urlencoded', stringify), user.hubUserInfoEndpointTest, core.httpsOnly, debug.rewriteUrl, core.validateStatus, core.reportOptionsError, user.retryOnError, core.jsonResponse));
export const checkForAuth = () => client.get('/login-verify/hub-user-info?source=documents').then(res => {
  return res;
});