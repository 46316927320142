'use es6';

import makeValidationResponse from '../../core/utils/makeValidationResponse';
export default ((apiPath, dataKey) => ({
  input,
  httpClient,
  validationLevel
}, callback) => {
  const query = {
    [dataKey]: encodeURIComponent(input),
    validationLevel
  };
  const returnInvalid = () => makeValidationResponse(input, false);
  const request = httpClient.post(apiPath, {
    query
  }).then(({
    success
  }) => makeValidationResponse(input, success), returnInvalid).catch(returnInvalid);
  callback(request);
  return request;
});