"use es6";

import { HTMLProjectionNode } from "../../projection/node/HTMLProjectionNode";
import { MeasureLayout } from "./layout/MeasureLayout";
const layout = {
  layout: {
    ProjectionNode: HTMLProjectionNode,
    MeasureLayout
  }
};
export { layout };