import ClassicLightTheme from 'foundations-theming/themes/classic/light/theme';
import NextDarkTheme from 'foundations-theming/themes/next/dark/theme';
import NextLightTheme from 'foundations-theming/themes/next/light/theme';
import PinkLightTheme from './mock-themes/pink/light/theme';
const LOCAL_STORAGE_THEME_KEY = 'trellis:theme';
export const getTheme = (themeName, themeMode) => {
  if (themeName === 'classic') {
    if (themeMode === 'light') {
      return ClassicLightTheme;
    }
  } else if (themeName === 'pink') {
    if (themeMode === 'light') {
      return PinkLightTheme;
    }
  } else if (themeName === 'next') {
    if (themeMode === 'light') {
      return NextLightTheme;
    } else if (themeMode === 'dark') {
      return NextDarkTheme;
    }
  }
  return undefined;
};
export const getLocalThemeOverride = () => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    if (!value) {
      return undefined;
    }
    const [themeName, themeMode] = value.split('-');
    return getTheme(themeName, themeMode);
  } catch (err) {
    return undefined;
  }
};
export const getThemeToProvide = (themeName, themeMode) => {
  let theme = getLocalThemeOverride();
  if (theme) {
    return theme;
  }
  theme = getTheme(themeName, themeMode);
  if (theme) {
    return theme;
  }
  throw new Error('Unsupported theme and mode combination.');
};