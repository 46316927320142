export const CLICK_MOVE = 'click-move';
export const CLICK_SHARE = 'click-share';
export const CLICK_PREVIEW = 'click-preview';
export const CLICK_DESCRIPTION = 'click-description';
export const CLICK_SHARE_SUMMARY = 'click-share-summary';
export const CLICK_DOWNLOAD = 'click-download';
export const CLICK_PREVIEW_SUMMARY = 'click-preview-summary';
export const CLICK_REUPLOAD = 'click-reupload';
export const CLICK_REUPLOAD_SUMMARY = 'click-reupload-summary';
export const CLICK_DELETE = 'click-delete';
export const CLICK_DOWNLOAD_SUMMARY = 'click-download-summary';
export const VIEWED_INDEX = 'viewed-index';
export const VIEWED_SUMMARY = 'viewed-summary';
export const UPLOAD_FILE_INDEX = 'upload-file-index';
export const CLICK_SHARE_RECIPIENT = 'click-share-recipient';
export const OPEN_VIEW_USAGE_DETAIL = 'open-view-usage-detail';
export const CLICK_VIEW_USAGE_DATE_EXPAND = 'click-view-usage-date-expand';
export const CLICKED_REPS_MEETING_LINK = 'clicked-reps-meeting-link';
export const VIEWED_REPS_PROFILE = 'viewed-reps-profile';
export const CLICKED_REPS_EMAIL = 'clicked-reps-email';
export const DOCUMENT_DOWNLOAD = 'document-download';
export const DOCUMENT_SHARE = 'document-share';
export const DOCUMENT_VIEW = 'document-view';
export const SHARE_PRIVATE = 'set-to-private';
export const SHARE_SPECIFIC = 'shared-with-users-and-teams';
export const SHARE_EVERYONE = 'shared-with-everyone';