"use es6";

import { scrapeMotionValuesFromProps } from "./utils/scrape-motion-values";
import { DOMVisualElement } from "../dom/DOMVisualElement";
import { buildSVGAttrs } from "./utils/build-attrs";
import { camelToDash } from "../dom/utils/camel-to-dash";
import { camelCaseAttributes } from "./utils/camel-case-attrs";
import { transformProps } from "../html/utils/transform";
import { renderSVG } from "./utils/render";
import { getDefaultValueType } from "../dom/value-types/defaults";
import { createBox } from "../../projection/geometry/models";
import { isSVGTag } from "./utils/is-svg-tag";
class SVGVisualElement extends DOMVisualElement {
  constructor() {
    super(...arguments);
    this.type = "svg";
    this.isSVGTag = false;
  }
  getBaseTargetFromProps(props, key) {
    return props[key];
  }
  readValueFromInstance(instance, key) {
    if (transformProps.has(key)) {
      const defaultType = getDefaultValueType(key);
      return defaultType ? defaultType.default || 0 : 0;
    }
    key = !camelCaseAttributes.has(key) ? camelToDash(key) : key;
    return instance.getAttribute(key);
  }
  measureInstanceViewportBox() {
    return createBox();
  }
  scrapeMotionValuesFromProps(props, prevProps, visualElement) {
    return scrapeMotionValuesFromProps(props, prevProps, visualElement);
  }
  build(renderState, latestValues, options, props) {
    buildSVGAttrs(renderState, latestValues, options, this.isSVGTag, props.transformTemplate);
  }
  renderInstance(instance, renderState, styleProp, projection) {
    renderSVG(instance, renderState, styleProp, projection);
  }
  mount(instance) {
    this.isSVGTag = isSVGTag(instance.tagName);
    super.mount(instance);
  }
}
export { SVGVisualElement };