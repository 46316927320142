"use es6";

const createAxisDelta = () => ({
  translate: 0,
  scale: 1,
  origin: 0,
  originPoint: 0
});
const createDelta = () => ({
  x: createAxisDelta(),
  y: createAxisDelta()
});
const createAxis = () => ({
  min: 0,
  max: 0
});
const createBox = () => ({
  x: createAxis(),
  y: createAxis()
});
export { createAxis, createAxisDelta, createBox, createDelta };