"use es6";

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["transition", "transitionEnd"];
import { transformProps } from "../../render/html/utils/transform";
import { animateMotionValue } from "./motion-value";
import { isWillChangeMotionValue } from "../../value/use-will-change/is";
import { setTarget } from "../../render/utils/setters";
import { getValueTransition } from "../utils/transitions";
import { getOptimisedAppearId } from "../optimized-appear/get-appear-id";
import { frame } from "../../frameloop/frame";

/**
 * Decide whether we should block this animation. Previously, we achieved this
 * just by checking whether the key was listed in protectedKeys, but this
 * posed problems if an animation was triggered by afterChildren and protectedKeys
 * had been set to true in the meantime.
 */
function shouldBlockAnimation({
  protectedKeys,
  needsAnimating
}, key) {
  const shouldBlock = protectedKeys.hasOwnProperty(key) && needsAnimating[key] !== true;
  needsAnimating[key] = false;
  return shouldBlock;
}
function animateTarget(visualElement, targetAndTransition, {
  delay = 0,
  transitionOverride,
  type
} = {}) {
  var _a;
  let {
      transition = visualElement.getDefaultTransition(),
      transitionEnd
    } = targetAndTransition,
    target = _objectWithoutPropertiesLoose(targetAndTransition, _excluded);
  const willChange = visualElement.getValue("willChange");
  if (transitionOverride) transition = transitionOverride;
  const animations = [];
  const animationTypeState = type && visualElement.animationState && visualElement.animationState.getState()[type];
  for (const key in target) {
    const value = visualElement.getValue(key, (_a = visualElement.latestValues[key]) !== null && _a !== void 0 ? _a : null);
    const valueTarget = target[key];
    if (valueTarget === undefined || animationTypeState && shouldBlockAnimation(animationTypeState, key)) {
      continue;
    }
    const valueTransition = Object.assign({
      delay,
      elapsed: 0
    }, getValueTransition(transition || {}, key));
    /**
     * If this is the first time a value is being animated, check
     * to see if we're handling off from an existing animation.
     */
    let isHandoff = false;
    if (window.HandoffAppearAnimations) {
      const appearId = getOptimisedAppearId(visualElement);
      if (appearId) {
        const elapsed = window.HandoffAppearAnimations(appearId, key, value, frame);
        if (elapsed !== null) {
          valueTransition.elapsed = elapsed;
          isHandoff = true;
        }
      }
    }
    value.start(animateMotionValue(key, value, valueTarget, visualElement.shouldReduceMotion && transformProps.has(key) ? {
      type: false
    } : valueTransition, visualElement, isHandoff));
    const animation = value.animation;
    if (animation) {
      if (isWillChangeMotionValue(willChange)) {
        willChange.add(key);
        animation.then(() => willChange.remove(key));
      }
      animations.push(animation);
    }
  }
  if (transitionEnd) {
    Promise.all(animations).then(() => {
      frame.update(() => {
        transitionEnd && setTarget(visualElement, transitionEnd);
      });
    });
  }
  return animations;
}
export { animateTarget };