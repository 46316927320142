import { hexToRGB } from './color';
export const getPageThumbnailStyles = (selected, brandingColor) => {
  if (!selected) {
    return undefined;
  }
  const {
    r,
    g,
    b
  } = hexToRGB(brandingColor);
  return {
    borderColor: brandingColor,
    boxShadow: `0 3px 5px 0 rgba(${r}, ${g}, ${b}, 0.3)`
  };
};