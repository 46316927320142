import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ViewerRecord from '../records/ViewerRecord';
import { checkForAuth } from '../api/HubSpotAuthApi';
export const useRequiresViewerInfo = () => {
  const [requiresViewerInfo, setRequiresViewerInfo] = useState(null);
  const [isRequested, setIsRequested] = useState(false);
  const {
    view,
    legalConsent
  } = useSelector(state => state);
  const {
    legalConsent: legalConsentRecord,
    legalConsentFailed
  } = legalConsent;
  const {
    presentation
  } = view.presentation;
  const cachedViewer = ViewerRecord.getFromCache();
  const isViewerCached = cachedViewer && ViewerRecord.hasEmail(cachedViewer);
  const isLoading = !legalConsentRecord && !legalConsentFailed || !presentation;
  useEffect(() => {
    if (isLoading || requiresViewerInfo !== null || isRequested) return;
    if (legalConsentFailed || isViewerCached) {
      setRequiresViewerInfo(false);
      return;
    } else if (legalConsentRecord.deckLegalConsentEnabled) {
      setRequiresViewerInfo(true);
      return;
    }
    if (presentation.skipForm || !presentation.hasActivity) {
      setRequiresViewerInfo(false);
      return;
    }
    setIsRequested(true);
    checkForAuth().then(() => {
      setRequiresViewerInfo(false);
    }, () => {
      setRequiresViewerInfo(true);
    }).catch(err => {
      throw err;
    });
  }, [isLoading, requiresViewerInfo, isRequested, legalConsentFailed, isViewerCached, presentation, legalConsentRecord]);
  return requiresViewerInfo;
};