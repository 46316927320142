export const headerPaddingStyles = {
  'extra-small': '400',
  small: '500',
  medium: '600',
  large: '700',
  'extra-large': '700'
};
export const panelStyles = {
  'extra-small': {
    paddingInlineStart: '400',
    marginBlockStart: '200'
  },
  small: {
    paddingInlineStart: '500',
    marginBlockStart: '400'
  },
  medium: {
    paddingInlineStart: '600',
    marginBlockStart: '400'
  },
  large: {
    paddingInlineStart: '700',
    marginBlockStart: '400'
  },
  'extra-large': {
    paddingInlineStart: '700',
    marginBlockStart: '400'
  }
};
export const chevronSize = {
  'extra-small': 'icon-100',
  small: 'icon-300',
  medium: 'icon-400',
  large: 'icon-500',
  'extra-large': 'icon-500'
};
export const headerElement = {
  'extra-small': 'p',
  small: 'h5',
  medium: 'h4',
  large: 'h2',
  'extra-large': 'h1'
};