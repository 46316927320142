'use es6';

import PropTypes from 'prop-types';
export const makeEventRelay = () => {
  const listeners = [];
  let wrappedHandler;
  const handle = evt => {
    if (typeof wrappedHandler === 'function') wrappedHandler(evt);
    listeners.forEach(listener => listener(evt));
  };
  return {
    getHandle: handler => {
      wrappedHandler = handler;
      return handle;
    },
    subscribe: listener => listeners.push(listener),
    unsubscribe: listener => listeners.splice(listeners.indexOf(listener), 1)
  };
};
export const eventRelayPropType = PropTypes.shape({
  subscribe: PropTypes.func.isRequired
});