import { PDF_ACTION_TYPES } from '../../constants/ActionTypes';
const {
  VIEW_PDF_ZOOM,
  VIEW_PDF_PAGE,
  VIEW_PDF_RESET
} = PDF_ACTION_TYPES;
const initialState = {
  pageIndex: 0,
  zoom: 1.0
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case VIEW_PDF_ZOOM:
      return Object.assign({}, state, {
        zoom: action.payload
      });
    case VIEW_PDF_PAGE:
      return Object.assign({}, state, {
        pageIndex: action.payload
      });
    case VIEW_PDF_RESET:
      return initialState;
    default:
      return state;
  }
});