"use es6";

import { HoverGesture } from "../../gestures/hover";
import { FocusGesture } from "../../gestures/focus";
import { PressGesture } from "../../gestures/press";
import { InViewFeature } from "./viewport/index";
const gestureAnimations = {
  inView: {
    Feature: InViewFeature
  },
  tap: {
    Feature: PressGesture
  },
  focus: {
    Feature: FocusGesture
  },
  hover: {
    Feature: HoverGesture
  }
};
export { gestureAnimations };