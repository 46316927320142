import { useCallback, useRef, useState, useLayoutEffect } from 'react';
import usePrevious from 'react-utils/hooks/usePrevious';
/**
 * A hook for implementing the controllable pattern.
 *
 * @param {*} value The current value of the controllable property
 * @param {*} defaultValue The default value of the controllable property
 * @param {Function} onChange An external handler to fire when `handleChange` is called
 * @return {Array} An array of the form `[computedValue, handleChange]`
 */
export function useControllable(value, defaultValue, onChange) {
  const [stateValue, setStateValue] = useState(defaultValue);

  // Store `onChange` in a ref so that our `handleChange` can be a constant
  const onChangeRef = useRef(onChange);
  useLayoutEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // Compute the return value
  const computedValue = value !== undefined ? value : stateValue;
  const handleChange = useCallback(evt => {
    setStateValue(evt.target.value);
    if (onChangeRef.current) onChangeRef.current(evt);
  }, []);

  // Edge case: If `value` has become `undefined`, reset the state value to the default
  const prevValue = usePrevious(value);
  if (prevValue !== value && value === undefined && stateValue !== defaultValue) {
    setStateValue(defaultValue);
  }
  return [computedValue, handleChange];
}