export const getCompanyName = ({
  domain,
  accountName,
  companyName
}) => {
  if (accountName) {
    return accountName;
  }
  if (companyName && companyName.indexOf('<') !== -1) {
    return companyName;
  }
  return domain;
};