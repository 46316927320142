import { initTracker } from 'documents-lib/js/tracking/tracker';
import * as PublicActivityApi from '../api/PublicActivityApi';
const viewPage = ({
  presentation,
  activityId,
  pageIndex
}) => {
  if (!presentation) return Promise.reject(new Error('No presentation'));
  return PublicActivityApi.viewPage(presentation.id, presentation.accessId, activityId, pageIndex);
};
export const configurePublicActivityMiddleware = (stateToPresentation, handlers) => ({
  getState
}) => {
  let activityId = null;
  return next => action => {
    switch (action.type) {
      case handlers.identify:
        {
          const {
            presentation
          } = stateToPresentation(getState());
          initTracker({
            isPublic: true
          });
          if (presentation === null || presentation === undefined) return;
          PublicActivityApi.createActivity(presentation.id, presentation.accessId, action.payload).then(activity => {
            activityId = activity;
            viewPage({
              presentation,
              activityId,
              pageIndex: 0
            }).catch(err => console.error(err));
          }).catch(err => console.error(err));
          break;
        }
      case handlers.viewPage:
        {
          if (activityId === null) {
            break;
          }
          const {
            presentation
          } = stateToPresentation(getState());
          viewPage({
            presentation,
            activityId,
            pageIndex: action.payload
          }).catch(err => console.error(err));
          break;
        }
      case handlers.finish:
        {
          if (activityId === null) {
            break;
          }
          const {
            presentation
          } = stateToPresentation(getState());
          if (presentation === null || presentation === undefined) return;
          PublicActivityApi.finishActivity(presentation.id, presentation.accessId, activityId).catch(err => console.error(err));
          break;
        }
      default:
        break;
    }
    return next(action);
  };
};