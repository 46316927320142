"use es6";

import { isZeroValueString } from "../../utils/is-zero-value-string";
function isNone(value) {
  if (typeof value === "number") {
    return value === 0;
  } else if (value !== null) {
    return value === "none" || value === "0" || isZeroValueString(value);
  } else {
    return true;
  }
}
export { isNone };