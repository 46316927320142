import { Record } from 'immutable';
import { SLINKY } from 'HubStyleTokens/colors';
class BrandingRecord extends Record({
  showSalesAd: true,
  primaryColor: SLINKY,
  secondaryColor: SLINKY,
  logoUrl: null,
  logoAltText: null,
  companyName: null
}, 'BrandingRecord') {}
export default BrandingRecord;