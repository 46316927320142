module.exports = {
  "/view/:deckId": {
    "success": {
      "public view": [
        "PREVIEW_LOAD"
      ],
      "document not found": [
        "DOCUMENT_NOT_FOUND"
      ]
    },
    "error": [
      "PREVIEW_FAIL"
    ]
  }
};