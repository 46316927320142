"use es6";

import { addPointerEvent } from "../events/add-pointer-event";
import { pipe } from "../utils/pipe";
import { isDragActive } from "./drag/utils/lock";
import { Feature } from "../motion/features/Feature";
import { frame } from "../frameloop/frame";
function addHoverEvent(node, isActive) {
  const eventName = isActive ? "pointerenter" : "pointerleave";
  const callbackName = isActive ? "onHoverStart" : "onHoverEnd";
  const handleEvent = (event, info) => {
    if (event.pointerType === "touch" || isDragActive()) return;
    const props = node.getProps();
    if (node.animationState && props.whileHover) {
      node.animationState.setActive("whileHover", isActive);
    }
    const callback = props[callbackName];
    if (callback) {
      frame.postRender(() => callback(event, info));
    }
  };
  return addPointerEvent(node.current, eventName, handleEvent, {
    passive: !node.getProps()[callbackName]
  });
}
class HoverGesture extends Feature {
  mount() {
    this.unmount = pipe(addHoverEvent(this.node, true), addHoverEvent(this.node, false));
  }
  unmount() {}
}
export { HoverGesture };