'use es6';

import getLangLocale from './getLangLocale';
import formatName from './formatName';
const formalNameLanguages = ['de', 'ja'];
export default (({
  givenName,
  familyName,
  email
}, options) => {
  const locale = options && options.locale || getLangLocale();
  return formalNameLanguages.indexOf(locale) >= 0 || !givenName ? formatName({
    firstName: givenName,
    lastName: familyName,
    email
  }, options) : givenName;
});