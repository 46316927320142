"use es6";

const isPrimaryPointer = event => {
  if (event.pointerType === "mouse") {
    return typeof event.button !== "number" || event.button <= 0;
  } else {
    /**
     * isPrimary is true for all mice buttons, whereas every touch point
     * is regarded as its own input. So subsequent concurrent touch points
     * will be false.
     *
     * Specifically match against false here as incomplete versions of
     * PointerEvents in very old browser might have it set as undefined.
     */
    return event.isPrimary !== false;
  }
};
export { isPrimaryPointer };