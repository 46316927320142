'use es6';

import PasswordPattern from 'PatternValidationJS/patterns/Password';
const VALIDATORS = {
  basic: input => {
    return PasswordPattern.test(input);
  }
};
export default ((input, {
  type
}) => {
  const validator = VALIDATORS[type] || VALIDATORS.basic;
  return validator(input);
});