export const PLACEMENTS_HORIZ = ['left', 'right'];
export const PLACEMENTS_VERT = ['top', 'bottom'];
export const PLACEMENTS_SIDES = [...PLACEMENTS_HORIZ, ...PLACEMENTS_VERT];
export const PLACEMENTS = [...PLACEMENTS_SIDES, 'left top', 'left bottom', 'right top', 'right bottom', 'top left', 'top right', 'bottom left', 'bottom right'];
export const FLOATING_UI_PLACEMENTS_MAP = {
  top: 'top',
  'top left': 'top-end',
  'top right': 'top-start',
  right: 'right',
  'right top': 'right-end',
  'right bottom': 'right-start',
  bottom: 'bottom',
  'bottom left': 'bottom-end',
  'bottom right': 'bottom-start',
  left: 'left',
  'left top': 'left-end',
  'left bottom': 'left-start'
};