"use es6";

import { noop } from "../utils/noop";
import { createRenderBatcher } from "./batcher";
const {
  schedule: frame,
  cancel: cancelFrame,
  state: frameData,
  steps
} = createRenderBatcher(typeof requestAnimationFrame !== "undefined" ? requestAnimationFrame : noop, true);
export { cancelFrame, frame, frameData, steps };