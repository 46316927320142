export const getVariantStyles = variant => {
  switch (variant) {
    case 'info':
      return {
        backgroundColor: 'fill-info-subtle',
        borderInlineColor: 'border-info-default',
        borderBlockColor: 'border-info-default'
      };
    case 'system-info':
      return {
        backgroundColor: 'fill-info-subtle'
      };
    case 'warning':
      return {
        backgroundColor: 'fill-caution-subtle',
        borderInlineColor: 'border-caution-default',
        borderBlockColor: 'border-caution-default'
      };
    case 'system-warning':
      return {
        backgroundColor: 'fill-caution-subtle'
      };
    case 'danger':
      return {
        backgroundColor: 'fill-alert-subtle',
        borderInlineColor: 'border-alert-default',
        borderBlockColor: 'border-alert-default'
      };
    case 'system-danger':
      return {
        backgroundColor: 'fill-alert-subtle'
      };
    case 'success':
      return {
        backgroundColor: 'fill-positive-subtle',
        borderInlineColor: 'border-positive-default',
        borderBlockColor: 'border-positive-default'
      };
    default:
      {
        const __exhaustiveCheck = variant;
        throw new Error(`Unhandled \`variant\` argument:\n ${variant}`);
      }
  }
};