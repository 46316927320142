import PortalIdParser from 'PortalIdParser';
import { getTrackedSignupUrl } from 'viral-links-lib/viral-tracking/viral-urls';
import { Flow } from 'signup-constants/Flow';
import { HubsTrackingAttr } from 'signup-constants/HubsTrackingAttr';
import { Intent } from 'signup-constants/Intent';
import { getFullUrl } from 'hubspot-url-utils';
const portalId = PortalIdParser.get();
export const baseHubSpotUrl = getFullUrl('app');
export const getRootUrl = () => `${baseHubSpotUrl}/documents/${portalId}`;
export const getSummaryPagePathname = id => `/summary/${id}`;
export const getSummaryPageURL = id => `${getRootUrl()}/summary/${id}`;
export const getContactProfileURL = vid => `${baseHubSpotUrl}/contacts/${portalId}/contact/${vid}/`;
export const getContactEmailURL = vid => `${getContactProfileURL(vid)}?interaction=email`;
export const getPreviewURL = presentationId => `${getRootUrl()}/preview/${presentationId}`;
export const getBrandingURL = () => `${baseHubSpotUrl}/settings/${portalId}/account-defaults/branding`;
export const getGDPRUrl = () => 'https://knowledge.hubspot.com/articles/kcs_article/documents/use-documents#gdpr';
export const getSignupUrl = () => {
  return getTrackedSignupUrl({
    flow: Flow.Sales,
    viralLinkType: 'documents',
    additionalParams: {
      [HubsTrackingAttr.Campaign]: 'hubspot-documents-virality',
      [HubsTrackingAttr.Medium]: 'virality',
      intent: Intent.salesDocuments,
      opt_sidebar: 'documents' // todo need enums for opt_sidebar values.
    }
  });
};
export const getFileVisibilityKBLink = () => 'https://knowledge.hubspot.com/documents/use-documents';