export const DOCUMENT_CREATE_STARTED = 'DOCUMENT_CREATE_STARTED';
export const DOCUMENT_CREATE_FAILED = 'DOCUMENT_CREATE_FAILED';
export const DOCUMENT_CREATE_SUCCEEDED = 'DOCUMENT_CREATE_SUCCEEDED';
export const DOCUMENTS_USAGE_FETCH_SUCCEEDED = 'DOCUMENTS_USAGE_FETCH_SUCCEEDED';
export const REUPLOAD_DOCUMENT_CREATE_SUCCEEDED = 'REUPLOAD_DOCUMENT_CREATE_SUCCEEDED';
export const REUPLOAD_DOCUMENT_CREATE_FAILED = 'REUPLOAD_DOCUMENT_CREATE_FAILED';
export const DOCUMENT_UPDATE_SUCCEEDED = 'DOCUMENT_UPDATE_SUCCEEDED';
export const DOCUMENTS_GDPR_SETTING_UPDATE_SUCCEEDED = 'DOCUMENTS_GDPR_SETTING_UPDATE_SUCCEEDED';
export const DOCUMENT_SUMMARY_FETCH_SUCCEEDED = 'DOCUMENT_SUMMARY_FETCH_SUCCEEDED';
export const DOCUMENT_SUMMARY_FETCH_FAILED = 'DOCUMENT_SUMMARY_FETCH_FAILED';
export const DOCUMENT_VISITORS_FETCH_STARTED = 'DOCUMENT_VISITORS_FETCH_STARTED';
export const DOCUMENT_VISITORS_FETCH_SUCCEEDED = 'DOCUMENT_VISITORS_FETCH_SUCCEEDED';
export const DOCUMENT_VISITORS_FETCH_FAILED = 'DOCUMENT_VISITORS_FETCH_FAILED';
export const DOCUMENTS_DELETE_SUCCEEDED = 'DOCUMENTS_DELETE_SUCCEEDED';
export const DOCUMENT_REVISITS_FETCH_SUCCEEDED = 'DOCUMENT_REVISITS_FETCH_SUCCEEDED';
export const DOCUMENT_REVISITS_FETCH_FAILED = 'DOCUMENT_REVISITS_FETCH_FAILED';
export const DOCUMENTS_GDPR_SETTING_FETCH_SUCCEEDED = 'DOCUMENTS_GDPR_SETTING_FETCH_SUCCEEDED';
export const DOCUMENTS_GDPR_SETTING_FETCH_FAILED = 'DOCUMENTS_GDPR_SETTING_FETCH_FAILED';
export const MOVE_DOCUMENT_TO_FOLDER_STARTED = 'MOVE_DOCUMENT_TO_FOLDER_STARTED';
export const REUPLOAD_PROGRESS_PERCENT = 'REUPLOAD_PROGRESS_PERCENT';
export const GOOGLE_API_LOAD_SUCCEEDED = 'GOOGLE_API_LOAD_SUCCEEDED';
export const GOOGLE_AUTHENTICATION_SUCCEEDED = 'GOOGLE_AUTHENTICATION_SUCCEEDED';
export const DOWNLOAD_DOCUMENT_STARTED = 'DOWNLOAD_DOCUMENT_STARTED';
export const FETCH_DOCUMENT_DESCRIPTION_SUCCEEDED = 'FETCH_DOCUMENT_DESCRIPTION_SUCCEEDED';
export const FETCH_DOCUMENT_DESCRIPTION_FAILED = 'FETCH_DOCUMENT_DESCRIPTION_FAILED';
export const TOGGLE_DOCUMENT_DESCRIPTION = 'TOGGLE_DOCUMENT_DESCRIPTION';
export const MEETINGS_ACTION_TYPES = {
  MEETINGS_LINK_FETCH_SUCCEEDED: 'MEETINGS_LINK_FETCH_SUCCEEDED'
};
export const PDF_ACTION_TYPES = {
  VIEW_PDF_PAGE: 'VIEW_PDF_PAGE',
  VIEW_PDF_ZOOM: 'VIEW_PDF_ZOOM',
  VIEW_PDF_RESET: 'VIEW_PDF_RESET'
};
export const PRESENTATION_ACTION_TYPES = {
  PUBLIC_PRESENTATION_FETCH_SUCCEEDED: 'PUBLIC_PRESENTATION_FETCH_SUCCEEDED',
  PUBLIC_PRESENTATION_FETCH_FAILED: 'PUBLIC_PRESENTATION_FETCH_FAILED',
  PUBLIC_PRESENTATION_NOT_FOUND: 'PUBLIC_PRESENTATION_NOT_FOUND',
  PUBLIC_PRESENTATION_NOT_AVAILABLE: 'PUBLIC_PRESENTATION_NOT_AVAILABLE',
  PRESENTATION_FETCH_SUCCEEDED: 'PRESENTATION_FETCH_SUCCEEDED',
  PRESENTATION_FETCH_FAILED: 'PRESENTATION_FETCH_FAILED',
  PRESENTATION_NOT_FOUND: 'PRESENTATION_NOT_FOUND',
  PRESENTATION_FETCH_FAILED_NO_PERMISSION: 'PRESENTATION_FETCH_FAILED_NO_PERMISSION'
};
export const GDPR_ACTION_TYPES = {
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED: 'PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED',
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED: 'PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED',
  PUBLIC_DOCUMENTS_GDPR_LANG_LOADING: 'PUBLIC_DOCUMENTS_GDPR_LANG_LOADING'
};