"use es6";

import { isSVGComponent } from "./is-svg-component";
import { createUseRender } from "../use-render";
import { svgMotionConfig } from "../../svg/config-motion";
import { htmlMotionConfig } from "../../html/config-motion";
function createDomMotionConfig(Component, {
  forwardMotionProps = false
}, preloadedFeatures, createVisualElement) {
  const baseConfig = isSVGComponent(Component) ? svgMotionConfig : htmlMotionConfig;
  return Object.assign({}, baseConfig, {
    preloadedFeatures,
    useRender: createUseRender(forwardMotionProps),
    createVisualElement,
    Component
  });
}
export { createDomMotionConfig };