'use es6';

import keyMirror from 'react-utils/keyMirror';
export const urlValidationTypes = keyMirror({
  basic: null,
  parseLeniently: null,
  parseStrictly: null,
  resolveDomain: null
});
export const urlValidationLevels = {
  [urlValidationTypes.parseLeniently]: 'PARSE_WITH_LENIENCE',
  [urlValidationTypes.parseStrictly]: 'PARSE_STRICTLY',
  [urlValidationTypes.resolveDomain]: 'RESOLVE_DOMAIN'
};