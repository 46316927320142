import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["id", "sticky", "timeout", "timestamp", "type", "onClose"];
import { FLOATING_ALERT_DEFAULT_TIMING } from 'HubStyleTokens/times';
const newFloatingAlertStore = () => {
  let alertId = 0;
  let alerts = {};
  let subscriptions = {};
  const alertsToArray = () => {
    return Object.keys(alerts).map(alertKey => alerts[alertKey]);
  };
  const setAlerts = nextAlerts => {
    alerts = nextAlerts;
    const alertArray = alertsToArray();
    Object.keys(subscriptions).forEach(key => {
      subscriptions[key](alertArray);
    });
  };
  return {
    newFloatingAlertStore,
    getAlerts() {
      return alerts;
    },
    getSubscriptions() {
      return subscriptions;
    },
    subscribe(key, updateCallback) {
      subscriptions[key] = updateCallback;
      updateCallback(alertsToArray());
    },
    unsubscribe(key) {
      const newSubscriptions = Object.assign({}, subscriptions);
      delete newSubscriptions[key];
      subscriptions = newSubscriptions;
    },
    addAlert(_ref) {
      let {
          id = `Store-${alertId++}`,
          sticky,
          timeout,
          timestamp = Date.now(),
          type,
          onClose
        } = _ref,
        alert = _objectWithoutPropertiesLoose(_ref, _excluded);
      const newAlert = Object.assign({}, alert, {
        id,
        onClose: (...args) => {
          if (onClose) onClose(...args);
          this.removeAlert(id);
        },
        timestamp,
        type
      });
      setAlerts(Object.assign({}, alerts, {
        [id]: newAlert
      }));
      if (!sticky && type !== 'danger') {
        const defaultTimeout = parseInt(FLOATING_ALERT_DEFAULT_TIMING, 10);
        const alertTimeout = timeout != null ? timeout : defaultTimeout;
        setTimeout(() => {
          this.removeAlert(id);
        }, alertTimeout);
      }
    },
    removeAlert(id) {
      const newAlerts = Object.assign({}, alerts);
      delete newAlerts[id];
      setAlerts(newAlerts);
    }
  };
};

// Workaround to avoid mixed exports, but still have access to a generator
// function for ease of testing.
const FloatingAlertStore = newFloatingAlertStore();
export default FloatingAlertStore;