import PortalIdParser from 'PortalIdParser';
// @ts-expect-error no types from package
import { getCookie } from 'hub-http/helpers/cookies';
const getLocalStorageKey = portalId => `PresentationsApp_${portalId}`;
export const get = () => {
  const localStorageString = getCookie(getLocalStorageKey(PortalIdParser.get()), document.cookie);
  if (!localStorageString) {
    return null;
  }
  return JSON.parse(decodeURIComponent(localStorageString));
};
export const set = (attrs = {}) => {
  const value = encodeURIComponent(JSON.stringify(attrs));
  const key = encodeURIComponent(getLocalStorageKey(PortalIdParser.get()));
  document.cookie = `${key}=${value}`;
};