import { PRESENTATION_ACTION_TYPES } from '../../constants/ActionTypes';
const {
  PUBLIC_PRESENTATION_FETCH_SUCCEEDED,
  PUBLIC_PRESENTATION_FETCH_FAILED,
  PRESENTATION_FETCH_SUCCEEDED,
  PRESENTATION_FETCH_FAILED,
  PUBLIC_PRESENTATION_NOT_FOUND,
  PRESENTATION_NOT_FOUND,
  PRESENTATION_FETCH_FAILED_NO_PERMISSION,
  PUBLIC_PRESENTATION_NOT_AVAILABLE
} = PRESENTATION_ACTION_TYPES;
const initialState = {
  presentation: null
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case PRESENTATION_FETCH_SUCCEEDED:
    case PUBLIC_PRESENTATION_FETCH_SUCCEEDED:
      return Object.assign({}, state, {
        presentation: action.payload
      });
    case PRESENTATION_FETCH_FAILED:
    case PUBLIC_PRESENTATION_FETCH_FAILED:
      return Object.assign({}, state, {
        presentation: undefined
      });
    case PRESENTATION_NOT_FOUND:
    case PUBLIC_PRESENTATION_NOT_FOUND:
      return Object.assign({}, state, {
        presentation: undefined,
        notFound: true
      });
    case PRESENTATION_FETCH_FAILED_NO_PERMISSION:
      return Object.assign({}, state, {
        presentation: undefined,
        noPermission: true
      });
    case PUBLIC_PRESENTATION_NOT_AVAILABLE:
      return Object.assign({}, state, {
        presentation: undefined,
        notAvailableEndDate: parseInt(action.payload.context.timestamp[0], 10)
      });
    default:
      return state;
  }
});