"use es6";

import { createProjectionNode } from "./create-projection-node";
import { addDomEvent } from "../../events/add-dom-event";
const DocumentProjectionNode = createProjectionNode({
  attachResizeListener: (ref, notify) => addDomEvent(ref, "resize", notify),
  measureScroll: () => ({
    x: document.documentElement.scrollLeft || document.body.scrollLeft,
    y: document.documentElement.scrollTop || document.body.scrollTop
  }),
  checkIsScrollRoot: () => true
});
export { DocumentProjectionNode };