"use es6";

import { AnimationFeature } from "./animation/index";
import { ExitAnimationFeature } from "./animation/exit";
const animations = {
  animation: {
    Feature: AnimationFeature
  },
  exit: {
    Feature: ExitAnimationFeature
  }
};
export { animations };