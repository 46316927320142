"use es6";

/**
 * Pipe
 * Compose other transformers to run linearily
 * pipe(min(20), max(40))
 * @param  {...functions} transformers
 * @return {function}
 */
const combineFunctions = (a, b) => v => b(a(v));
const pipe = (...transformers) => transformers.reduce(combineFunctions);
export { pipe };