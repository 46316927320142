'use es6';

import UrlParts from '../parts/UrlParts';
const BLACKLISTED_WRAPPING_CHARS = '\\s\\(\\)\\[\\]\\{\\}';
export default (({
  isGlobal = false,
  protocolRequired = false,
  domain = UrlParts.domain,
  protocol = UrlParts.protocol,
  path = UrlParts.path,
  file = UrlParts.file,
  search = UrlParts.search
} = {}) => {
  let flags = 'i';
  const maybeProtocol = protocolRequired ? protocol : `${protocol}?`;
  let urlRegexStr = `${maybeProtocol}${domain}(?:${path}(?:${file})?)?${search}?`;
  if (isGlobal) {
    flags = `${flags}g`;
    urlRegexStr = `[^${BLACKLISTED_WRAPPING_CHARS}]*?${urlRegexStr}[^${BLACKLISTED_WRAPPING_CHARS}]*?`;
  } else {
    urlRegexStr = `^${urlRegexStr}$`;
  }
  return new RegExp(urlRegexStr, flags);
});