hns('I18n.translations.bn', {
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u %n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 2,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%",
        "precision": 0
      }
    },
    "human": {
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": "Byte",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "tb": "TB"
        }
      }
    }
  }
});

