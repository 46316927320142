"use es6";

import { drag } from "../../motion/features/drag";
import { layout } from "../../motion/features/layout";
import { domAnimation } from "./features-animation";

/**
 * @public
 */
const domMax = Object.assign({}, domAnimation, drag, layout);
export { domMax };