"use es6";

import { useMemo } from 'react';
import { isForcedMotionValue } from "../../motion/utils/is-forced-motion-value";
import { isMotionValue } from "../../value/utils/is-motion-value";
import { buildHTMLStyles } from "./utils/build-styles";
import { createHtmlRenderState } from "./utils/create-render-state";
function copyRawValuesOnly(target, source, props) {
  for (const key in source) {
    if (!isMotionValue(source[key]) && !isForcedMotionValue(key, props)) {
      target[key] = source[key];
    }
  }
}
function useInitialMotionValues({
  transformTemplate
}, visualState, isStatic) {
  return useMemo(() => {
    const state = createHtmlRenderState();
    buildHTMLStyles(state, visualState, {
      enableHardwareAcceleration: !isStatic
    }, transformTemplate);
    return Object.assign({}, state.vars, state.style);
  }, [visualState]);
}
function useStyle(props, visualState, isStatic) {
  const styleProp = props.style || {};
  const style = {};
  /**
   * Copy non-Motion Values straight into style
   */
  copyRawValuesOnly(style, styleProp, props);
  Object.assign(style, useInitialMotionValues(props, visualState, isStatic));
  return style;
}
function useHTMLProps(props, visualState, isStatic) {
  // The `any` isn't ideal but it is the type of createElement props argument
  const htmlProps = {};
  const style = useStyle(props, visualState, isStatic);
  if (props.drag && props.dragListener !== false) {
    // Disable the ghost element when a user drags
    htmlProps.draggable = false;
    // Disable text selection
    style.userSelect = style.WebkitUserSelect = style.WebkitTouchCallout = "none";
    // Disable scrolling on the draggable direction
    style.touchAction = props.drag === true ? "none" : `pan-${props.drag === "x" ? "y" : "x"}`;
  }
  if (props.tabIndex === undefined && (props.onTap || props.onTapStart || props.whileTap)) {
    htmlProps.tabIndex = 0;
  }
  htmlProps.style = style;
  return htmlProps;
}
export { copyRawValuesOnly, useHTMLProps };