export const BACKSPACE = 8;
export const BRACKET_LEFT = 219;
export const BRACKET_RIGHT = 221;
export const DOWN = 40;
export const ENTER = 13;
export const TAB = 9;
export const ESC = 27;
export const LEFT = 37;
export const NBSP = 160;
export const RIGHT = 39;
export const SPACE = 32;
export const UP = 38;
export const Y = 89;
export const Z = 90;
export const NUMBER_0 = 48;
export const NUMBER_1 = 49;
export const NUMBER_2 = 50;
export const NUMBER_3 = 51;
export const NUMBER_4 = 52;
export const NUMBER_5 = 53;
export const NUMBER_6 = 54;
export const NUMBER_7 = 55;
export const NUMBER_8 = 56;
export const NUMBER_9 = 57;
export const KEYPAD_NUMBER_0 = 96;
export const KEYPAD_NUMBER_1 = 97;
export const KEYPAD_NUMBER_2 = 98;
export const KEYPAD_NUMBER_3 = 99;
export const KEYPAD_NUMBER_4 = 100;
export const KEYPAD_NUMBER_5 = 101;
export const KEYPAD_NUMBER_6 = 102;
export const KEYPAD_NUMBER_7 = 103;
export const KEYPAD_NUMBER_8 = 104;
export const KEYPAD_NUMBER_9 = 105;