'use es6';

import PropTypes from 'prop-types';
import UIFormControl from 'UIComponents/form/UIFormControl';

/*
  Only include props that are shared between validator components.
*/
export const propTypes = Object.assign({}, UIFormControl.propTypes, {
  children: PropTypes.node.isRequired,
  validationMessage: PropTypes.object,
  validateDefaultValue: PropTypes.bool,
  trimInputValue: PropTypes.bool.isRequired,
  onError: PropTypes.func,
  onSuccess: PropTypes.func
});
export const defaultProps = Object.assign({}, UIFormControl.defaultProps, {
  trimInputValue: false
});