"use es6";

import { useContext, useMemo } from 'react';
import { MotionContext } from "./index";
import { getCurrentTreeVariants } from "./utils";
function useCreateMotionContext(props) {
  const {
    initial,
    animate
  } = getCurrentTreeVariants(props, useContext(MotionContext));
  return useMemo(() => ({
    initial,
    animate
  }), [variantLabelsAsDependency(initial), variantLabelsAsDependency(animate)]);
}
function variantLabelsAsDependency(prop) {
  return Array.isArray(prop) ? prop.join(" ") : prop;
}
export { useCreateMotionContext };