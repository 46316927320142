import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { configurePublicActivityMiddleware } from '../middleware/PublicActivityMiddleware';
import { configureViewerCacheMiddleware } from '../middleware/ViewerCacheMiddleware';
import { PDF_ACTION_TYPES } from 'documents-lib/js/constants/ActionTypes';
import { VIEWER_ACTION_TYPES } from '../constants/ActionTypes';
import { errorMiddleware } from 'documents-lib/js/middleware/errorMiddleware';
const {
  VIEW_PDF_PAGE
} = PDF_ACTION_TYPES;
const {
  VIEWER_IDENTIFIED,
  VIEWER_FINISHED
} = VIEWER_ACTION_TYPES;
const composeWithEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const PublicActivityMiddleware = configurePublicActivityMiddleware(state => state.view.presentation, {
  identify: VIEWER_IDENTIFIED,
  viewPage: VIEW_PDF_PAGE,
  finish: VIEWER_FINISHED
});
const ViewerCacheMiddleware = configureViewerCacheMiddleware(VIEWER_IDENTIFIED);
const createStoreWithMiddleware = composeWithEnhancers(applyMiddleware(thunk, PublicActivityMiddleware, ViewerCacheMiddleware, errorMiddleware))(createStore);
export function configureStore(initialState) {
  return createStoreWithMiddleware(rootReducer, initialState);
}