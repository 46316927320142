// pared down from
// https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/debounceCore.js
export default ((fn, wait, context) => {
  let timeout;
  function debouncer(...args) {
    debouncer.reset();
    const callback = function callback() {
      fn.apply(context, args);
    };
    timeout = setTimeout(callback, wait);
  }
  debouncer.reset = function () {
    clearTimeout(timeout);
  };
  return debouncer;
});