import { Record } from 'immutable';
import BrandingRecord from './BrandingRecord';
class PresentationRecord extends Record({
  accessId: null,
  brandingMetadata: null,
  hasActivity: null,
  id: null,
  name: null,
  pdfUrl: null,
  portalId: null,
  sharedWithVid: null,
  shortLink: null,
  skipForm: null,
  slides: null,
  user: {},
  userId: null
}, 'PresentationRecord') {
  static create(presentation) {
    return new PresentationRecord(Object.assign({}, presentation, {
      brandingMetadata: new BrandingRecord(presentation.brandingMetadata)
    }));
  }

  // Move to a util inside of documents-ui since the types of documentRecord and userContainer are unknown
  static convertFromDocumentRecord(documentRecord, pdfUrl, brandingRecord, userContainer) {
    const {
      id,
      portalId,
      title,
      slides
    } = documentRecord.toObject();
    const user = userContainer.get();
    return new PresentationRecord({
      id,
      portalId,
      name: title,
      slides: slides.toJS(),
      skipForm: true,
      user: {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email
      },
      hasActivity: false,
      pdfUrl,
      brandingMetadata: brandingRecord
    });
  }
}
export default PresentationRecord;