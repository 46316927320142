"use es6";

import { animations } from "../../motion/features/animations";
import { gestureAnimations } from "../../motion/features/gestures";
import { createDomVisualElement } from "./create-visual-element";

/**
 * @public
 */
const domAnimation = Object.assign({
  renderer: createDomVisualElement
}, animations, gestureAnimations);
export { domAnimation };