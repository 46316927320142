"use es6";

import { useMemo } from 'react';
import { copyRawValuesOnly } from "../html/use-props";
import { buildSVGAttrs } from "./utils/build-attrs";
import { createSvgRenderState } from "./utils/create-render-state";
import { isSVGTag } from "./utils/is-svg-tag";
function useSVGProps(props, visualState, _isStatic, Component) {
  const visualProps = useMemo(() => {
    const state = createSvgRenderState();
    buildSVGAttrs(state, visualState, {
      enableHardwareAcceleration: false
    }, isSVGTag(Component), props.transformTemplate);
    return Object.assign({}, state.attrs, {
      style: Object.assign({}, state.style)
    });
  }, [visualState]);
  if (props.style) {
    const rawStyles = {};
    copyRawValuesOnly(rawStyles, props.style, props);
    visualProps.style = Object.assign({}, rawStyles, visualProps.style);
  }
  return visualProps;
}
export { useSVGProps };