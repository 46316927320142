import { createSelector } from 'reselect';
// TODO (slitvinov): Find possible replacement of this file. It's a super weird way to make simple math based on some abstract state that doesn't exist

const pageIndexSelector = state => state.pageIndex;
const totalPagesSelector = state => state.totalPages;
const pageNumberSelector = createSelector(pageIndexSelector, pageIndex => pageIndex + 1);
const previousPageDisabledSelector = createSelector(pageIndexSelector, pageIndex => pageIndex <= 0);
const nextPageDisabledSelector = createSelector(pageNumberSelector, totalPagesSelector, (pageNumber, totalPages) => pageNumber === totalPages);
export default createSelector(pageNumberSelector, previousPageDisabledSelector, nextPageDisabledSelector, (pageNumber, previousPageDisabled, nextPageDisabled) => ({
  pageNumber,
  previousPageDisabled,
  nextPageDisabled
}));