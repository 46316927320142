'use es6';

import PasswordRuleParts from './parts/PasswordRuleParts';
const {
  minLength,
  maxLength,
  lowercase,
  uppercase,
  numberSymbolWhitespace
} = PasswordRuleParts;
const PASSWORD_RULES = {
  length: new RegExp(`^.{${minLength},${maxLength}}$`),
  lowercase: new RegExp(`${lowercase}`),
  uppercase: new RegExp(`${uppercase}`),
  numberSymbolWhitespace: new RegExp(`${numberSymbolWhitespace}`)
};
export default PASSWORD_RULES;