"use es6";

import { color } from "../../../value/types/color/index";
import { complex } from "../../../value/types/complex/index";
import { dimensionValueTypes } from "./dimensions";
import { testValueType } from "./test";

/**
 * A list of all ValueTypes
 */
const valueTypes = [...dimensionValueTypes, color, complex];
/**
 * Tests a value against the list of ValueTypes
 */
const findValueType = v => valueTypes.find(testValueType(v));
export { findValueType };