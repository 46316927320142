"use es6";

import { color } from "../../../value/types/color/index";
import { filter } from "../../../value/types/complex/filter";
import { numberValueTypes } from "./number";

/**
 * A map of default value types for common values
 */
const defaultValueTypes = Object.assign({}, numberValueTypes, {
  // Color props
  color,
  backgroundColor: color,
  outlineColor: color,
  fill: color,
  stroke: color,
  // Border props
  borderColor: color,
  borderTopColor: color,
  borderRightColor: color,
  borderBottomColor: color,
  borderLeftColor: color,
  filter,
  WebkitFilter: filter
});
/**
 * Gets the default ValueType for the provided value key
 */
const getDefaultValueType = key => defaultValueTypes[key];
export { defaultValueTypes, getDefaultValueType };