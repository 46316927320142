'use es6';

import BasePatternFactory from '../lib/BasePatternFactory';
import UrlRegex from '../regex/UrlRegex';
import UrlWithProtocolRegex from '../regex/UrlWithProtocolRegex';
import UrlRegexFactory from '../regex/factories/UrlRegexFactory';
import UrlParts from '../regex/parts/UrlParts';
const _makeGlobalUrlRegex = () => {
  return UrlRegexFactory({
    isGlobal: true,
    search: UrlParts.ungroupedSearch
  });
};
const UrlPattern = BasePatternFactory({
  name: 'Url',
  validator: (input, options = {}) => {
    if (options && options.requireProtocol) {
      return UrlWithProtocolRegex.test(input);
    }
    return UrlRegex.test(input);
  }
});
UrlPattern.pick = input => {
  const GlobalUrlRegex = _makeGlobalUrlRegex();
  const results = [];
  let matchedGroups = GlobalUrlRegex.exec(input);
  while (matchedGroups !== null) {
    const start = matchedGroups.index;
    const end = start + matchedGroups[0].length - 1;
    results.push({
      start,
      end
    });
    matchedGroups = GlobalUrlRegex.exec(input);
  }
  return results;
};
export default UrlPattern;