import { getLanguages, CONSENT_FIELDS } from 'SharedLegalStrings/consent/constants';
import { getDefaultConsentTextWithCompanyName } from 'SharedLegalStrings/consent/getDefaultConsentText';
function transformHubSettingsArray(hubSettings) {
  const settings = {};
  hubSettings.forEach(setting => settings[setting.key] = setting.value);
  return settings;
}
export const getDefaultConsentCopy = ({
  communicationConsentSettings,
  companyName
}) => {
  const settings = transformHubSettingsArray(communicationConsentSettings);
  const consentCopy = {};

  // Remove duplicate lang keys ('da', 'no-no', 'zh-tw')
  const languages = [...new Set(getLanguages())];
  languages.forEach(lang => {
    consentCopy[lang] = {};
    CONSENT_FIELDS.forEach(field => {
      const settingsKey = `${field}:${lang}`;
      if (Object.prototype.hasOwnProperty.call(settings, settingsKey)) {
        consentCopy[lang][field] = settings[settingsKey];
        delete settings[settingsKey];
      } else {
        consentCopy[lang][field] = getDefaultConsentTextWithCompanyName(field, {
          locale: lang,
          // We assume the companyName is defined otherwise the code doesn't make sense
          companyName: companyName
        });
      }
    });
  });
  return consentCopy;
};