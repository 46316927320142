import { GDPR_ACTION_TYPES } from '../constants/ActionTypes';
const {
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED,
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED,
  PUBLIC_DOCUMENTS_GDPR_LANG_LOADING
} = GDPR_ACTION_TYPES;
const initialState = {
  legalConsent: null,
  legalConsentFailed: false,
  legalConsentLangLoading: false
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case PUBLIC_DOCUMENTS_GDPR_LANG_LOADING:
      return Object.assign({}, state, {
        legalConsentLangLoading: true
      });
    case PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED:
      return Object.assign({}, state, {
        legalConsent: action.payload,
        legalConsentLangLoading: false
      });
    case PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED:
      return Object.assign({}, state, {
        legalConsentFailed: true
      });
    default:
      return state;
  }
});