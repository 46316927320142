import { getDefaultConsentTextWithCompanyName } from 'SharedLegalStrings/consent/getDefaultConsentText';
import Raven from 'raven-js';
const checkboxRegex = /\[{"communicationTypeId":([0-9]+|null|undefined),"label":"(.*)"}\]/;
export const setCommunicationConsentCheckboxForLang = ({
  locale,
  localeOptions,
  communicationConsentCheckboxes,
  companyName
}) => {
  const firstCommunicationCheckbox = communicationConsentCheckboxes && communicationConsentCheckboxes[0];
  const defaultCommunicationTypeId = firstCommunicationCheckbox && firstCommunicationCheckbox.communicationTypeId;
  let regexResults;
  try {
    // Despite the variable name, this always contains one checkbox object
    // Uses a regex to parse because the string is not always valid JSON
    // https://issues.hubspotcentral.com/browse/DOCUMENTS-505
    regexResults = localeOptions.communicationConsentCheckboxes.match(checkboxRegex);
    if (regexResults === null || regexResults.length < 3) {
      throw new Error('No regex match on communicationConsentCheckboxes.');
    }
  } catch (err) {
    Raven.captureException(new Error('Error while parsing communication consent checkboxes.'), {
      extra: {
        err,
        communicationConsentCheckboxes: localeOptions.communicationConsentCheckboxes,
        regexResults
      }
    });

    // This is the text that would have been added by `addDefaultConsentCopy`
    const fallbackString = getDefaultConsentTextWithCompanyName('communicationConsentCheckboxes', {
      locale,
      companyName: companyName
    });
    regexResults = fallbackString.match(checkboxRegex);
  }
  const langCommunicationConsentCheckboxes = [{
    communicationTypeId: JSON.parse(regexResults[1]),
    // Expected to be a number, null, or undefined
    label: regexResults[2]
  }];
  if (langCommunicationConsentCheckboxes[0].communicationTypeId === null || langCommunicationConsentCheckboxes[0].communicationTypeId === undefined) {
    langCommunicationConsentCheckboxes[0].communicationTypeId = defaultCommunicationTypeId;
  }
  return langCommunicationConsentCheckboxes;
};