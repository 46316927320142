'use es6';

import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { validateEmailFast, validateEmailExpensive } from './emailValidationAPI';
import debounceTimers from 'ui-addon-form-validation/core/constants/debounceTimers';
import makeAsyncDebouncedValidator from 'ui-addon-form-validation/core/utils/makeAsyncDebouncedValidator';
const debouncedValidateEmailFast = makeAsyncDebouncedValidator(validateEmailFast, debounceTimers.LOW);
const debouncedValidateEmailExpensive = makeAsyncDebouncedValidator(validateEmailExpensive, debounceTimers.LOW);
const VALIDATORS = {
  basic: input => {
    return EmailAddressPattern.test(input);
  },
  apiFast: (input, httpClient) => {
    const isValid = EmailAddressPattern.test(input);
    if (!isValid) {
      return false;
    }
    return debouncedValidateEmailFast(input, {
      httpClient
    });
  },
  apiFull: (input, httpClient) => {
    const isValid = EmailAddressPattern.test(input);
    if (!isValid) {
      return false;
    }
    return debouncedValidateEmailExpensive(input, {
      httpClient
    });
  }
};
export default ((input, {
  type,
  httpClient
}) => {
  const validator = VALIDATORS[type] || VALIDATORS.basic;
  return validator(input, httpClient);
});