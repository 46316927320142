"use es6";

import { isAnimationControls } from "../../animation/utils/is-animation-controls";
import { isVariantLabel } from "./is-variant-label";
import { variantProps } from "./variant-props";
function isControllingVariants(props) {
  return isAnimationControls(props.animate) || variantProps.some(name => isVariantLabel(props[name]));
}
function isVariantNode(props) {
  return Boolean(isControllingVariants(props) || props.variants);
}
export { isControllingVariants, isVariantNode };