/* hs-eslint ignored failing-rules */
/* eslint-disable no-prototype-builtins */

'use es6';

export default (callback => {
  // Waits for CPU idleness to reduce lag while user is typing.
  if (window.hasOwnProperty('requestIdleCallback')) {
    window.requestIdleCallback(callback);
  } else {
    callback();
  }
});