import memoize from 'transmute/memoize';
import { OLAF, OBSIDIAN, SLINKY } from 'HubStyleTokens/colors';
import Raven from 'raven-js';
const SHORTHAND_HEX_PATTERN = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const REGULAR_HEX_PATTERN = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
function _hexToRGB(hex) {
  let rgbObject;
  try {
    const regularHex = hex.replace(SHORTHAND_HEX_PATTERN, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
    const result = REGULAR_HEX_PATTERN.exec(regularHex);
    rgbObject = {
      // TODO (slitvinov): replace with if clause instead of potentially triggering the error
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        hex
      }
    });
    rgbObject = {
      r: 0,
      g: 0,
      b: 0
    };
  }
  return rgbObject;
}
export const hexToRGB = memoize(_hexToRGB);
function getL(hex) {
  const {
    r,
    g,
    b
  } = hexToRGB(hex);
  const rPercent = r / 255.0;
  const gPercent = g / 255.0;
  const bPercent = b / 255.0;
  const rL = r <= 0.03928 ? rPercent / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const gL = g <= 0.03928 ? gPercent / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const bL = b <= 0.03928 ? bPercent / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  return 0.2126 * rL + 0.7152 * gL + 0.0722 * bL;
}
function isDarkColor(backgroundHex) {
  const backL = getL(backgroundHex);
  const textL = getL(OLAF);
  const contrastRatio = (textL + 0.05) / (backL + 0.05);
  return contrastRatio > 2.0;
}
export function getTextColor(primaryColor) {
  return isDarkColor(primaryColor) ? OLAF : OBSIDIAN;
}
export function getDarkBrandingColor(primaryColor, secondaryColor, defaultColor = SLINKY) {
  if (primaryColor && isDarkColor(primaryColor)) {
    return primaryColor;
  }
  if (secondaryColor && isDarkColor(secondaryColor)) {
    return secondaryColor;
  }
  return defaultColor;
}