module.exports = {
  "documentInteraction": {
    "name": "Document Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": [
        "click-move",
        "click-share",
        "click-preview",
        "click-download",
        "click-share-summary",
        "click-preview-summary",
        "click-reupload",
        "click-reupload-summary",
        "click-delete",
        "click-download-summary",
        "viewed-index",
        "viewed-summary",
        "click-share-recipient",
        "clicked-reps-meeting-link",
        "clicked-reps-email",
        "viewed-rep-profile",
        "document-download",
        "document-share",
        "document-view",
        "viewed-reps-profile",
        "set-to-private",
        "shared-with-users-and-teams",
        "shared-with-everyone",
        "open-view-usage-detail",
        "click-view-usage-date-expand",
        "click-description"
      ]
    },
    "namespace": "documents"
  },
  "indexHoverActionInteraction": {
    "name": "Button hover",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "type": "string",
      "action": [
        "click-share",
        "click-download"
      ]
    },
    "namespace": "documents"
  },
  "uploadFileInteraction": {
    "name": "Upload file",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "source": "string",
      "action": [
        "upload-file-index"
      ]
    },
    "namespace": "documents"
  },
  "documentDescriptionInteraction": {
    "name": "Document Description Interaction",
    "class": "interaction",
    "properties": {
      "screen": "string",
      "action": [
        "generate-description",
        "regenerate-description"
      ]
    },
    "namespace": "documents"
  },
  "feedbackCopyButtonsInteraction": {
    "name": "Document Feedback Copy Buttons Interaction",
    "class": "interaction",
    "properties": {
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "click-upvote",
        "click-upvote-undo",
        "click-downvote",
        "click-downvote-undo",
        "click-copy",
        "submit-positive-form",
        "submit-negative-form"
      ]
    },
    "namespace": "documents"
  },
  "aiButtonInteraction": {
    "name": "aiButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "featureId": {
        "type": "string",
        "isOptional": true
      },
      "featureCategoryId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonInteraction": {
    "name": "copilotActionButtonInteraction",
    "class": "interaction",
    "properties": {
      "type": {
        "type": "string"
      },
      "prompt": {
        "type": "string",
        "isOptional": true
      },
      "hasPrefillValues": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copyMessageInteraction": {
    "name": "copyMessageInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_copied"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "feedbackInteraction": {
    "name": "inlineFeedbackInteraction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "prompt_upvoted",
          "prompt_upvote_removed",
          "prompt_downvoted",
          "prompt_downvote_removed"
        ]
      },
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  },
  "copilotActionButtonPageView": {
    "name": "aiComponentsView",
    "class": "view",
    "properties": {
      "action": [
        "page_view"
      ],
      "screen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "ai-components-ui-library",
    "version": "1"
  }
};