import { PDF_ACTION_TYPES } from '../constants/ActionTypes';
const {
  VIEW_PDF_PAGE,
  VIEW_PDF_ZOOM,
  VIEW_PDF_RESET
} = PDF_ACTION_TYPES;
export const changePage = pageIndex => ({
  type: VIEW_PDF_PAGE,
  payload: pageIndex
});
export const changeZoom = scale => ({
  type: VIEW_PDF_ZOOM,
  payload: scale
});
export const reset = () => ({
  type: VIEW_PDF_RESET
});