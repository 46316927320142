import PortalIdParser from 'PortalIdParser';
import apiClient from '../utils//SPMRedirectNoAuthApiClient';
import LegalConsentRecord from '../records/LegalConsentRecord';
const BASE_URL = 'presentations/v1';
export const fetchLegalConsent = (presentationId, accessId) => apiClient.get(`${BASE_URL}/gdpr/public/presentations/${presentationId}/viewer-form`, {
  query: {
    portalId: PortalIdParser.get(),
    accessId
  }
}).then(res => {
  return LegalConsentRecord.fromJS(res);
});
// Expected HTTP 204 - No Content
export const postLegalConsentFeedback = ({
  presentationId,
  accessId,
  formGuid,
  email,
  deckLegalConsentEnabled,
  consentReceived,
  communicationTypeId,
  legalConsentOptions
}) => apiClient.post(`${BASE_URL}/gdpr/public/presentations/viewer-form`, {
  data: {
    portalId: PortalIdParser.get(),
    presentationId,
    accessId,
    formGuid,
    email,
    deckLegalConsentEnabled,
    consentReceived,
    communicationTypeId,
    legalConsentOptions
  }
});