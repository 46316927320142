"use es6";

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["autoplay", "delay", "type", "repeat", "repeatDelay", "repeatType"];
import { flushKeyframeResolvers } from "../../render/utils/KeyframesResolver";
import { instantAnimationState } from "../../utils/use-instant-transition-state";
import { canAnimate } from "./utils/can-animate";
import { getFinalKeyframe } from "./waapi/utils/get-final-keyframe";
class BaseAnimation {
  constructor(_ref) {
    let {
        autoplay = true,
        delay = 0,
        type = "keyframes",
        repeat = 0,
        repeatDelay = 0,
        repeatType = "loop"
      } = _ref,
      options = _objectWithoutPropertiesLoose(_ref, _excluded);
    // Track whether the animation has been stopped. Stopped animations won't restart.
    this.isStopped = false;
    this.hasAttemptedResolve = false;
    this.options = Object.assign({
      autoplay,
      delay,
      type,
      repeat,
      repeatDelay,
      repeatType
    }, options);
    this.updateFinishedPromise();
  }
  /**
   * A getter for resolved data. If keyframes are not yet resolved, accessing
   * this.resolved will synchronously flush all pending keyframe resolvers.
   * This is a deoptimisation, but at its worst still batches read/writes.
   */
  get resolved() {
    if (!this._resolved && !this.hasAttemptedResolve) {
      flushKeyframeResolvers();
    }
    return this._resolved;
  }
  /**
   * A method to be called when the keyframes resolver completes. This method
   * will check if its possible to run the animation and, if not, skip it.
   * Otherwise, it will call initPlayback on the implementing class.
   */
  onKeyframesResolved(keyframes, finalKeyframe) {
    this.hasAttemptedResolve = true;
    const {
      name,
      type,
      velocity,
      delay,
      onComplete,
      onUpdate,
      isGenerator
    } = this.options;
    /**
     * If we can't animate this value with the resolved keyframes
     * then we should complete it immediately.
     */
    if (!isGenerator && !canAnimate(keyframes, name, type, velocity)) {
      // Finish immediately
      if (instantAnimationState.current || !delay) {
        onUpdate === null || onUpdate === void 0 ? void 0 : onUpdate(getFinalKeyframe(keyframes, this.options, finalKeyframe));
        onComplete === null || onComplete === void 0 ? void 0 : onComplete();
        this.resolveFinishedPromise();
        return;
      }
      // Finish after a delay
      else {
        this.options.duration = 0;
      }
    }
    const resolvedAnimation = this.initPlayback(keyframes, finalKeyframe);
    if (resolvedAnimation === false) return;
    this._resolved = Object.assign({
      keyframes,
      finalKeyframe
    }, resolvedAnimation);
    this.onPostResolved();
  }
  onPostResolved() {}
  /**
   * Allows the returned animation to be awaited or promise-chained. Currently
   * resolves when the animation finishes at all but in a future update could/should
   * reject if its cancels.
   */
  then(resolve, reject) {
    return this.currentFinishedPromise.then(resolve, reject);
  }
  updateFinishedPromise() {
    this.currentFinishedPromise = new Promise(resolve => {
      this.resolveFinishedPromise = resolve;
    });
  }
}
export { BaseAnimation };