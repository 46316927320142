"use es6";

import { resolveVariant } from "../../render/utils/resolve-dynamic-variants";
import { animateTarget } from "./visual-element-target";
import { animateVariant } from "./visual-element-variant";
import { frame } from "../../frameloop/frame";
function animateVisualElement(visualElement, definition, options = {}) {
  visualElement.notify("AnimationStart", definition);
  let animation;
  if (Array.isArray(definition)) {
    const animations = definition.map(variant => animateVariant(visualElement, variant, options));
    animation = Promise.all(animations);
  } else if (typeof definition === "string") {
    animation = animateVariant(visualElement, definition, options);
  } else {
    const resolvedDefinition = typeof definition === "function" ? resolveVariant(visualElement, definition, options.custom) : definition;
    animation = Promise.all(animateTarget(visualElement, resolvedDefinition, options));
  }
  return animation.then(() => {
    frame.postRender(() => {
      visualElement.notify("AnimationComplete", definition);
    });
  });
}
export { animateVisualElement };