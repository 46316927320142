'use es6';

import UrlPattern from 'PatternValidationJS/patterns/Url';
import debounceTimers from 'ui-addon-form-validation/core/constants/debounceTimers';
import makeAsyncDebouncedValidator from 'ui-addon-form-validation/core/utils/makeAsyncDebouncedValidator';
import { urlValidationLevels, urlValidationTypes } from './urlValidationConstants';
import { validateUrl } from './urlValidationAPI';
const debouncedUrlValidation = makeAsyncDebouncedValidator(validateUrl, debounceTimers.MEDIUM);
export default ((input, {
  type,
  httpClient,
  requireProtocol,
  requireHttps
}) => {
  const validator = type === urlValidationTypes.basic ? () => true : debouncedUrlValidation;
  const validationLevel = urlValidationLevels[type];
  if (requireHttps) {
    return /^https:\/\//i.test(input) && UrlPattern.test(input, {
      requireProtocol
    }) && validator(input, {
      httpClient,
      validationLevel
    });
  }
  return UrlPattern.test(input, {
    requireProtocol
  }) && validator(input, {
    httpClient,
    validationLevel
  });
});