'use es6';

import makeValidationResponse from 'ui-addon-form-validation/core/utils/makeValidationResponse';

// Callback param exists to allow async operations within a debounced function.
export function validateEmailFast({
  input,
  httpClient
}, callback) {
  const encodedInput = encodeURIComponent(input);
  const request = httpClient.get(`emailvalidation/v1/validate/fast?email=${encodedInput}`).then(({
    success
  }) => {
    return makeValidationResponse(input, success);
  }, () => {
    return makeValidationResponse(input, false);
  }).catch(() => {
    return makeValidationResponse(input, false);
  });
  callback(request);
  return request;
}

// Callback param exists to allow async operations within a debounced function.
export function validateEmailExpensive({
  input,
  httpClient
}, callback) {
  const encodedInput = encodeURIComponent(input);
  const request = httpClient.put('emailvalidation/v1/validate', {
    data: {
      email: encodedInput
    }
  }).then(({
    success
  }) => {
    return makeValidationResponse(input, success);
  }, () => {
    return makeValidationResponse(input, false);
  }).catch(() => {
    return makeValidationResponse(input, false);
  });
  callback(request);
  return request;
}