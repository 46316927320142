"use es6";

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["transitionEnd", "transition"];
import { resolveFinalValueInKeyframes } from "../../utils/resolve-value";
import { motionValue } from "../../value/index";
import { resolveVariant } from "./resolve-dynamic-variants";

/**
 * Set VisualElement's MotionValue, creating a new MotionValue for it if
 * it doesn't exist.
 */
function setMotionValue(visualElement, key, value) {
  if (visualElement.hasValue(key)) {
    visualElement.getValue(key).set(value);
  } else {
    visualElement.addValue(key, motionValue(value));
  }
}
function setTarget(visualElement, definition) {
  const resolved = resolveVariant(visualElement, definition);
  let _ref = resolved || {},
    {
      transitionEnd = {},
      transition = {}
    } = _ref,
    target = _objectWithoutPropertiesLoose(_ref, _excluded);
  target = Object.assign({}, target, transitionEnd);
  for (const key in target) {
    const value = resolveFinalValueInKeyframes(target[key]);
    setMotionValue(visualElement, key, value);
  }
}
export { setTarget };