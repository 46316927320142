import I18n from 'I18n';
import fetchCompanyName from './fetchCompanyName';
export function getDefaultConsentTextWithCompanyName(field, {
  communicationTypeId,
  companyName,
  locale
}) {
  if (field === 'communicationConsentCheckboxes') {
    return JSON.stringify([{
      communicationTypeId: communicationTypeId || null,
      label: I18n.text(`SharedLegalStrings.consent.${field}.label`, {
        locale,
        companyName
      })
    }]);
  } else {
    return I18n.text(`SharedLegalStrings.consent.${field}`, {
      locale,
      companyName
    });
  }
}
export default function getDefaultConsentText(field, {
  locale
}) {
  return fetchCompanyName().then(companyName => getDefaultConsentTextWithCompanyName(field, {
    locale,
    companyName
  }));
}