import { Record } from 'immutable';
import LegalConsentOptionsRecord from './LegalConsentOptionsRecord';
class LegalConsentRecord extends Record({
  consentFormId: null,
  deckId: null,
  deckLegalConsentEnabled: null,
  legalConsentOptions: new LegalConsentOptionsRecord(),
  portalId: null,
  consentTextLang: null
}, 'LegalConsentRecord') {
  static fromJS({
    accountName,
    communicationConsentSettings = [],
    companyName,
    consentFormId,
    consentTextLang,
    deckId,
    deckLegalConsentEnabled,
    domain,
    legalConsentOptions,
    portalId
  }) {
    let legalConsentOptionsRecord = null;
    if (deckLegalConsentEnabled && legalConsentOptions // This field is defined as optional in API docs but it wouldn't work without it
    ) {
      legalConsentOptionsRecord = LegalConsentOptionsRecord.create({
        accountName,
        communicationConsentSettings,
        companyName,
        consentTextLang,
        // TODO: This field shouldn't really be on that record but rather on LegalConsentRecord itself (based on API docs)
        domain,
        legalConsentOptions
      });
    }
    return new LegalConsentRecord({
      consentFormId,
      deckId,
      deckLegalConsentEnabled,
      legalConsentOptions: legalConsentOptionsRecord,
      portalId
    });
  }
}
export default LegalConsentRecord;