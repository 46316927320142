"use es6";

import { renderSVG } from "./utils/render";
import { scrapeMotionValuesFromProps } from "./utils/scrape-motion-values";
import { makeUseVisualState } from "../../motion/utils/use-visual-state";
import { createSvgRenderState } from "./utils/create-render-state";
import { buildSVGAttrs } from "./utils/build-attrs";
import { isSVGTag } from "./utils/is-svg-tag";
import { frame } from "../../frameloop/frame";
const svgMotionConfig = {
  useVisualState: makeUseVisualState({
    scrapeMotionValuesFromProps: scrapeMotionValuesFromProps,
    createRenderState: createSvgRenderState,
    onMount: (props, instance, {
      renderState,
      latestValues
    }) => {
      frame.read(() => {
        try {
          renderState.dimensions = typeof instance.getBBox === "function" ? instance.getBBox() : instance.getBoundingClientRect();
        } catch (e) {
          // Most likely trying to measure an unrendered element under Firefox
          renderState.dimensions = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          };
        }
      });
      frame.render(() => {
        buildSVGAttrs(renderState, latestValues, {
          enableHardwareAcceleration: false
        }, isSVGTag(instance.tagName), props.transformTemplate);
        renderSVG(instance, renderState);
      });
    }
  })
};
export { svgMotionConfig };