import { Record } from 'immutable';
import getLangLocale from 'I18n/utils/getLangLocale';
import getLang from 'I18n/utils/getLang';
import { getDefaultConsentCopy } from '../utils/getDefaultConsentCopy';
import { getCompanyName } from '../utils/getCompanyName';
import { getBrowserLanguage } from '../utils/getBrowserLanguage';
import { setCommunicationConsentCheckboxForLang } from '../utils/setCommunicationConsentCheckboxForLang';
class LegalConsentOptionsRecord extends Record({
  communicationConsentCheckboxes: null,
  communicationConsentText: null,
  consentTextLang: null,
  isLegitimateInterest: null,
  privacyPolicyText: null,
  processingConsentText: null,
  processingConsentType: null
}, 'LegalConsentOptionsRecord') {
  static create({
    accountName,
    communicationConsentSettings,
    companyName,
    consentTextLang,
    domain,
    legalConsentOptions
  }) {
    const {
      isLegitimateInterest,
      processingConsentType,
      communicationConsentCheckboxes
    } = legalConsentOptions;

    // We assume that consentTextLang is defined otherwise the code doesn't make sense
    const lang = getBrowserLanguage() ? getLangLocale() : consentTextLang;
    const effectiveCompanyName = getCompanyName({
      domain,
      companyName,
      accountName
    });
    const consentCopy = getDefaultConsentCopy({
      communicationConsentSettings,
      companyName: effectiveCompanyName
    });
    const localeOptions = consentCopy[lang] || consentCopy[getLang()] || consentCopy['en'];
    const locale = lang || getLang() || 'en';
    const updatedCommunicationConsentCheckboxes = setCommunicationConsentCheckboxForLang({
      locale,
      localeOptions,
      communicationConsentCheckboxes,
      companyName: effectiveCompanyName
    });
    const communicationConsentText = localeOptions.communicationConsentText;
    const privacyPolicyText = localeOptions.privacyPolicyText;
    const processingConsentText = localeOptions.implicitProcessingConsentText;
    return new LegalConsentOptionsRecord({
      communicationConsentCheckboxes: updatedCommunicationConsentCheckboxes,
      communicationConsentText,
      isLegitimateInterest,
      privacyPolicyText,
      processingConsentText,
      processingConsentType
    });
  }
}
export default LegalConsentOptionsRecord;