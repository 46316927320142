import { MEETINGS_ACTION_TYPES } from '../../constants/ActionTypes';
const {
  MEETINGS_LINK_FETCH_SUCCEEDED
} = MEETINGS_ACTION_TYPES;
const initialState = null;
export default ((state = initialState, action) => {
  switch (action.type) {
    case MEETINGS_LINK_FETCH_SUCCEEDED:
      return action.payload;
    default:
      return state;
  }
});