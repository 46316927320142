import PortalIdParser from 'PortalIdParser';
import apiClient from 'hub-http/clients/noAuthApiClient';
const baseUrl = 'presentations/v1/revisit';
export function createActivity(presentationId, accessId, viewer) {
  return apiClient.post(`${baseUrl}/async/${presentationId}`, {
    query: {
      portalId: PortalIdParser.get(),
      accessId
    },
    data: viewer
  });
}

// returns 204 No Content
export function viewPage(presentationId, accessId, activityId, pageIndex) {
  return apiClient.post(`${baseUrl}/${presentationId}/${activityId}/page/${pageIndex}`, {
    query: {
      portalId: PortalIdParser.get(),
      accessId
    }
  });
}

// returns 204 No Content
export function finishActivity(presentationId, accessId, activityId) {
  return apiClient.post(`${baseUrl}/${presentationId}/${activityId}/finish`, {
    query: {
      portalId: PortalIdParser.get(),
      accessId
    }
  });
}