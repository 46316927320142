"use es6";

import { makeUseVisualState } from "../../motion/utils/use-visual-state";
import { scrapeMotionValuesFromProps } from "./utils/scrape-motion-values";
import { createHtmlRenderState } from "./utils/create-render-state";
const htmlMotionConfig = {
  useVisualState: makeUseVisualState({
    scrapeMotionValuesFromProps,
    createRenderState: createHtmlRenderState
  })
};
export { htmlMotionConfig };