"use es6";

/**
 * Converts seconds to milliseconds
 *
 * @param seconds - Time in seconds.
 * @return milliseconds - Converted time in milliseconds.
 */
const secondsToMilliseconds = seconds => seconds * 1000;
const millisecondsToSeconds = milliseconds => milliseconds / 1000;
export { millisecondsToSeconds, secondsToMilliseconds };