/**
 * Instead of implementing this utility directly, use either the ./pick or ./omit utilities which implement this one.
 * Those utilities are designed to mimic the functionality of _.pick and _.omit.
 * @param {Object} object - object to run filters on
 * @param {Boolean} include - true if the filters should include properties of the object on the returned value.
 *                            false if it should exclude them from the returned value.
 * @param {String|String[]} filters - property name or names to include/exclude
 */
const objectFilter = (object, include, ...filters) => {
  if (object == null) return object;
  const filtersAsArray = filters.reduce((filterAccum, filter) => {
    if (typeof filter === 'string') {
      return [...filterAccum, filter];
    }
    return [/* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    ...filterAccum, ...filter];
  }, []);
  return Object.keys(object).reduce((prev, key) => {
    const passesFilter = include ? Object.assign({}, prev, {
      [key]: object[key]
    }) : prev;
    const failsFilter = include ? prev : Object.assign({}, prev, {
      [key]: object[key]
    });
    return filtersAsArray.includes(key) ? passesFilter : failsFilter;
  }, {});
};
export default objectFilter;