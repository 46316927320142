import identity from 'transmute/identity';
// @ts-expect-error: No type
import { createAction } from 'flux-actions';
import { checkForAuth } from '../api/HubSpotAuthApi';
import ViewerRecord from '../records/ViewerRecord';
import { VIEWER_ACTION_TYPES } from '../constants/ActionTypes';
const {
  VIEWER_IDENTIFIED,
  VIEWER_FINISHED
} = VIEWER_ACTION_TYPES;
const createIdentifyAction = createAction(VIEWER_IDENTIFIED, identity);
const createFinishedAction = createAction(VIEWER_FINISHED, identity);
export const identifyViewer = (presentation, viewerInfo) => (dispatch, getState) => {
  const {
    legalConsent,
    legalConsentFailed
  } = getState().legalConsent;
  const cachedViewer = ViewerRecord.getFromCache();
  if (legalConsentFailed) {
    dispatch(createIdentifyAction(ViewerRecord.createEmpty()));
    return;
  }
  if (legalConsent && legalConsent.deckLegalConsentEnabled) {
    if (cachedViewer && ViewerRecord.hasEmail(cachedViewer)) {
      dispatch(createIdentifyAction(cachedViewer));
      return;
    }

    // if data privacy is on and viewerInfo is not defined yet, don't record the viewer
    if (!presentation.skipForm && !viewerInfo) {
      return;
    }
    dispatch(createIdentifyAction(ViewerRecord.create(viewerInfo)));
    return;
  }
  checkForAuth().then(auth => {
    const authViewer = ViewerRecord.createFromAuth(auth);
    if (cachedViewer && ViewerRecord.equals(cachedViewer, authViewer)) {
      dispatch(createIdentifyAction(cachedViewer));
      return;
    }
    dispatch(createIdentifyAction(authViewer));
    return;
  }, () => {
    if (cachedViewer && ViewerRecord.hasEmail(cachedViewer)) {
      dispatch(createIdentifyAction(cachedViewer));
      return;
    }
    if (presentation.skipForm) {
      if (cachedViewer) {
        dispatch(createIdentifyAction(cachedViewer));
        return;
      }
      dispatch(createIdentifyAction(ViewerRecord.createEmpty()));
      return;
    }
    if (!presentation.hasActivity) {
      dispatch(createIdentifyAction(ViewerRecord.create({
        vid: presentation.sharedWithVid
      })));
      return;
    }

    // if email is required and viewerInfo is not defined yet, don't record the viewer
    if (!presentation.skipForm && !viewerInfo) {
      return;
    }
    dispatch(createIdentifyAction(ViewerRecord.create(viewerInfo)));
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const finishActivity = () => dispatch => {
  dispatch(createFinishedAction());
};