"use es6";

function renderHTML(element, {
  style,
  vars
}, styleProp, projection) {
  Object.assign(element.style, style, projection && projection.getProjectionStyles(styleProp));
  // Loop over any CSS variables and assign those.
  for (const key in vars) {
    element.style.setProperty(key, vars[key]);
  }
}
export { renderHTML };