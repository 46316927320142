'use es6';

import { css } from 'styled-components';
import { MODAL_BACKGROUND_TRANSITION_TIMING } from 'HubStyleTokens/times';
export const getOverlayTransition = dialogTransitionTime => css([".private-modal-dialog--overlay{transition-property:", ";transition-delay:", "ms;transition-duration:", ";background-color:", ";}"], ({
  transitioning
}) => transitioning ? 'background-color' : 'none', ({
  open
}) => open ? 0 : dialogTransitionTime, MODAL_BACKGROUND_TRANSITION_TIMING, ({
  open
}) => `rgba(45, 62, 80, ${open ? '0.79' : '0'})`);