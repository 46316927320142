import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
import { DOCUMENT_INTERACTION, INDEX_HOVER_ACTION_INTERACTION, UPLOAD_FILE_INTERACTION, DOCUMENT_DESCRIPTION_INTERACTION } from './events';
import { SHARE_PRIVATE, SHARE_SPECIFIC, SHARE_EVERYONE } from './actions';
import * as SharingOptionTypes from 'sales-content-partitioning/constants/SharingOptionTypes';
import { makeUuid } from 'usage-tracker-core/common/helpers';
let tracker;
const uuid = makeUuid();
export const initTracker = ({
  isPublic = false
} = {}) => {
  const options = {
    events
  };
  if (isPublic) {
    options.allowUnauthed = true;
    options.properties = {
      deviceId: uuid
    };
  }
  tracker = createTracker(options);
  return tracker;
};
export const track = (event, eventProps = {}) => {
  if (!tracker) {
    throw new Error('Tracker has not been initialized');
  }
  const {
    screen = 'index'
  } = eventProps;
  tracker.track(event, Object.assign({
    screen
  }, eventProps));
};
export const trackInteraction = action => track(DOCUMENT_INTERACTION, {
  action
});
export const trackHover = ({
  action,
  type
}) => track(INDEX_HOVER_ACTION_INTERACTION, {
  action,
  type
});
export const trackUpload = ({
  action,
  source
}) => track(UPLOAD_FILE_INTERACTION, {
  action,
  source
});
export const trackDescriptionInteraction = ({
  action
}) => track(DOCUMENT_DESCRIPTION_INTERACTION, {
  action
});
export const trackPermissionSave = ({
  sharingOption
}) => {
  let action;
  switch (sharingOption) {
    case SharingOptionTypes.PRIVATE:
      action = SHARE_PRIVATE;
      break;
    case SharingOptionTypes.SPECIFIC:
      action = SHARE_SPECIFIC;
      break;
    case SharingOptionTypes.EVERYONE:
    default:
      action = SHARE_EVERYONE;
  }
  trackInteraction(action);
};