import { getUserId } from '../utils/getUserId';
import * as MeetingsPublicApi from '../api/MeetingsPublicApi';
import { MEETINGS_ACTION_TYPES } from '../constants/ActionTypes';
const {
  MEETINGS_LINK_FETCH_SUCCEEDED
} = MEETINGS_ACTION_TYPES;
export const fetchMeetingLinkAction = () => {
  return (dispatch, getState) => {
    const appState = getState();
    const userId = getUserId(appState);
    MeetingsPublicApi.fetchMeetingLink(userId).then(({
      link
    }) => dispatch({
      type: MEETINGS_LINK_FETCH_SUCCEEDED,
      payload: link
    })).catch(err => {
      console.error(err);
    });
  };
};