"use es6";

/**
 * Generate a list of every possible transform key.
 */
const transformPropOrder = ["transformPerspective", "x", "y", "z", "translateX", "translateY", "translateZ", "scale", "scaleX", "scaleY", "rotate", "rotateX", "rotateY", "rotateZ", "skew", "skewX", "skewY"];
/**
 * A quick lookup for transform props.
 */
const transformProps = new Set(transformPropOrder);
export { transformPropOrder, transformProps };