"use es6";

import { number } from "../../../value/types/numbers/index";
import { px, percent, degrees, vw, vh } from "../../../value/types/numbers/units";
import { testValueType } from "./test";
import { auto } from "./type-auto";

/**
 * A list of value types commonly used for dimensions
 */
const dimensionValueTypes = [number, px, percent, degrees, vw, vh, auto];
/**
 * Tests a dimensional value against the list of dimension ValueTypes
 */
const findDimensionValueType = v => dimensionValueTypes.find(testValueType(v));
export { dimensionValueTypes, findDimensionValueType };