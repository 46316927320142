"use es6";

import { mixNumber } from "./number";
import { warning } from "../errors";
import { hslaToRgba } from "../hsla-to-rgba";
import { hex } from "../../value/types/color/hex";
import { rgba } from "../../value/types/color/rgba";
import { hsla } from "../../value/types/color/hsla";
import { mixImmediate } from "./immediate";

// Linear color space blending
// Explained https://www.youtube.com/watch?v=LKnqECcg6Gw
// Demonstrated http://codepen.io/osublake/pen/xGVVaN
const mixLinearColor = (from, to, v) => {
  const fromExpo = from * from;
  const expo = v * (to * to - fromExpo) + fromExpo;
  return expo < 0 ? 0 : Math.sqrt(expo);
};
const colorTypes = [hex, rgba, hsla];
const getColorType = v => colorTypes.find(type => type.test(v));
function asRGBA(color) {
  const type = getColorType(color);
  warning(Boolean(type), `'${color}' is not an animatable color. Use the equivalent color code instead.`);
  if (!Boolean(type)) return false;
  let model = type.parse(color);
  if (type === hsla) {
    // TODO Remove this cast - needed since Framer Motion's stricter typing
    model = hslaToRgba(model);
  }
  return model;
}
const mixColor = (from, to) => {
  const fromRGBA = asRGBA(from);
  const toRGBA = asRGBA(to);
  if (!fromRGBA || !toRGBA) {
    return mixImmediate(from, to);
  }
  const blended = Object.assign({}, fromRGBA);
  return v => {
    blended.red = mixLinearColor(fromRGBA.red, toRGBA.red, v);
    blended.green = mixLinearColor(fromRGBA.green, toRGBA.green, v);
    blended.blue = mixLinearColor(fromRGBA.blue, toRGBA.blue, v);
    blended.alpha = mixNumber(fromRGBA.alpha, toRGBA.alpha, v);
    return rgba.transform(blended);
  };
};
export { mixColor, mixLinearColor };