"use es6";

/**
 * TODO: When we move from string as a source of truth to data models
 * everything in this folder should probably be referred to as models vs types
 */
// If this number is a decimal, make it just five decimal places
// to avoid exponents
const sanitize = v => Math.round(v * 100000) / 100000;
const floatRegex = /\x2D?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)/g;
const colorRegex = /(?:#[0-9a-f]{3,8}|(?:rgb|h[s\u017F]l)a?\((?:\x2D?[\.0-9]+%?[\t-\r ,\xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]+){2}\x2D?[\.0-9]+%?[\t-\r \xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]*(?:[,\/][\t-\r \xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]*)?(?:\b[0-9]+(?:\.[0-9]+)?|\.[0-9]+)?%?\))/gi;
const singleColorRegex = /^(?:#[0-9a-f]{3,8}|(?:rgb|h[s\u017F]l)a?\((?:\x2D?[\.0-9]+%?[\t-\r ,\xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]+){2}\x2D?[\.0-9]+%?[\t-\r \xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]*(?:[,\/][\t-\r \xA0\u1680\u2000-\u200A\u2028\u2029\u202F\u205F\u3000\uFEFF]*)?(?:\b[0-9]+(?:\.[0-9]+)?|\.[0-9]+)?%?\))$/i;
function isString(v) {
  return typeof v === "string";
}
export { colorRegex, floatRegex, isString, sanitize, singleColorRegex };