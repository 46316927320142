import I18n from 'I18n';
let languages = [];
export const getLanguages = () => {
  if (languages.length > 0) {
    return languages;
  }

  // hack: defining this here as an object to de-dupe keys that
  //       might be defined in both baseLocales and publicLocales
  const combinedLocaleData = Object.assign({}, I18n.baseLocales, I18n.publicLocales);
  languages = [...Object.keys(combinedLocaleData)];
  return languages;
};
export const CONSENT_FIELDS = ['communicationConsentCheckboxes', 'communicationConsentText', 'explicitProcessingConsentCheckboxLabel', 'explicitProcessingConsentText', 'implicitProcessingConsentText', 'legitimateInterestPrivacyPolicyText', 'privacyPolicyText'];