"use es6";

import { clamp } from "../../../utils/clamp";
import { number, alpha } from "../numbers/index";
import { sanitize } from "../utils";
import { isColorString, splitColor } from "./utils";
const clampRgbUnit = v => clamp(0, 255, v);
const rgbUnit = Object.assign({}, number, {
  transform: v => Math.round(clampRgbUnit(v))
});
const rgba = {
  test: isColorString("rgb", "red"),
  parse: splitColor("red", "green", "blue"),
  transform: ({
    red,
    green,
    blue,
    alpha: alpha$1 = 1
  }) => "rgba(" + rgbUnit.transform(red) + ", " + rgbUnit.transform(green) + ", " + rgbUnit.transform(blue) + ", " + sanitize(alpha.transform(alpha$1)) + ")"
};
export { rgbUnit, rgba };