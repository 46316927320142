"use es6";

import { isPrimaryPointer } from "./utils/is-primary-pointer";
function extractEventInfo(event, pointType = "page") {
  return {
    point: {
      x: event[`${pointType}X`],
      y: event[`${pointType}Y`]
    }
  };
}
const addPointerInfo = handler => {
  return event => isPrimaryPointer(event) && handler(event, extractEventInfo(event));
};
export { addPointerInfo, extractEventInfo };