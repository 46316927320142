"use es6";

import { Fragment } from 'react';
import { HTMLVisualElement } from "../html/HTMLVisualElement";
import { SVGVisualElement } from "../svg/SVGVisualElement";
import { isSVGComponent } from "./utils/is-svg-component";
const createDomVisualElement = (Component, options) => {
  return isSVGComponent(Component) ? new SVGVisualElement(options, {
    enableHardwareAcceleration: false
  }) : new HTMLVisualElement(options, {
    allowProjection: Component !== Fragment,
    enableHardwareAcceleration: true
  });
};
export { createDomVisualElement };