import { Component } from 'react';

/**
 * A class-component that just renders its `children`. Can be used with
 * `findDOMNode` in order to obtain a reference to the `children`'s DOM node.
 *
 * This is mostly useful when:
 * 1. a function component needs to use `findDOMNode(this)` but can't because
 *    it doesn't have a backing instance
 * 2. a component needs to call `findDOMNode` on an element it renders *but
 *    does not define* without using `cloneElement` to attach a ref to the
 *    element
 */
export class ChildrenWrapper extends Component {
  render() {
    return this.props.children;
  }
}