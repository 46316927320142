"use es6";

import { DragGesture } from "../../gestures/drag/index";
import { PanGesture } from "../../gestures/pan/index";
import { MeasureLayout } from "./layout/MeasureLayout";
import { HTMLProjectionNode } from "../../projection/node/HTMLProjectionNode";
const drag = {
  pan: {
    Feature: PanGesture
  },
  drag: {
    Feature: DragGesture,
    ProjectionNode: HTMLProjectionNode,
    MeasureLayout
  }
};
export { drag };