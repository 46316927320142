"use es6";

import { clamp } from "../../../utils/clamp";
const number = {
  test: v => typeof v === "number",
  parse: parseFloat,
  transform: v => v
};
const alpha = Object.assign({}, number, {
  transform: v => clamp(0, 1, v)
});
const scale = Object.assign({}, number, {
  default: 1
});
export { alpha, number, scale };