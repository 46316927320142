import createStandardI18nProvider from 'I18n/init/providers/createStandardI18nProvider';
import registerBasicData from 'I18n/init/register/lazy/registerBasicData';
import lang from 'i2l?query=sporks!documents-lib/lang/view/en.lyaml';
import { needsToLoadLocale } from './needsToLoadLocale';
export const gdprI18nProvider = createStandardI18nProvider();
export const ensureGdprLang = consentObject => {
  const {
    consentTextLang
  } = consentObject;
  if (!needsToLoadLocale(consentObject)) {
    return Promise.resolve(consentObject);
  }
  const langAvailable = Promise.all([gdprI18nProvider.register(lang), registerBasicData(gdprI18nProvider)]);
  const langEnabled = consentTextLang !== 'en';
  gdprI18nProvider.setLocale({
    locale: consentTextLang,
    langEnabled
  });
  return langAvailable.then(() => consentObject);
};