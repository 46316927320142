/* hs-eslint ignored failing-rules */
/* eslint-disable promise/catch-or-return */

'use es6';

import debounce from 'react-utils/debounce';
import requestIdleCallbackShim from './requestIdleCallbackShim';
import defer from 'hs-promise-utils/defer';
export default ((asyncFn, debounceTimer) => {
  const debouncedAsyncFn = debounce(asyncFn, debounceTimer);
  return (input, opts = {}) => {
    const deferred = defer();
    requestIdleCallbackShim(() => {
      debouncedAsyncFn(Object.assign({
        input
      }, opts), resp => {
        resp.then(isValid => {
          deferred.resolve(isValid);
        });
      });
    });
    return deferred.promise;
  };
});