import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const HUBS_ENDPOINT = 'hubs/v1/hubs';
const portalId = PortalIdParser.get();
let cachedCompanyName = null;
export default function fetchCompanyName() {
  if (cachedCompanyName) {
    return Promise.resolve(cachedCompanyName);
  }
  return http.get(`${HUBS_ENDPOINT}/${portalId}`).then(response => {
    const {
      domain,
      accountName,
      companyFooter
    } = response;
    let companyName = domain;
    if (accountName) {
      companyName = accountName;
    } else if (companyFooter && companyFooter.indexOf('<') !== -1) {
      companyName = companyFooter;
    }
    cachedCompanyName = companyName;
    return companyName;
  });
}