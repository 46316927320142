"use es6";

import { animateMotionValue } from "./motion-value";
import { motionValue } from "../../value/index";
import { isMotionValue } from "../../value/utils/is-motion-value";
function animateSingleValue(value, keyframes, options) {
  const motionValue$1 = isMotionValue(value) ? value : motionValue(value);
  motionValue$1.start(animateMotionValue("", motionValue$1, keyframes, options));
  return motionValue$1.animation;
}
export { animateSingleValue };