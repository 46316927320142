export const getOrientationStyles = orientation => {
  if (orientation === 'horizontal') {
    return {
      '& > *': {
        '&:first-child': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0
        },
        '&:last-child': {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0
        },
        '&:not(:first-child)': {
          marginLeft: '-1px'
        },
        '&:not(:first-child):not(:last-child)': {
          borderRadius: 0
        }
      }
    };
  }
  return {
    '& > *': {
      '&:first-child': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      },
      '&:last-child': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0
      },
      '&:not(:first-child)': {
        marginTop: '-1px'
      },
      '&:not(:first-child):not(:last-child)': {
        borderRadius: 0
      }
    }
  };
};