"use es6";

import { isMotionValue } from "../../../value/utils/is-motion-value";
import { scrapeMotionValuesFromProps as scrapeMotionValuesFromProps$1 } from "../../html/utils/scrape-motion-values";
import { transformPropOrder } from "../../html/utils/transform";
function scrapeMotionValuesFromProps(props, prevProps, visualElement) {
  const newValues = scrapeMotionValuesFromProps$1(props, prevProps, visualElement);
  for (const key in props) {
    if (isMotionValue(props[key]) || isMotionValue(prevProps[key])) {
      const targetKey = transformPropOrder.indexOf(key) !== -1 ? "attr" + key.charAt(0).toUpperCase() + key.substring(1) : key;
      newValues[targetKey] = props[key];
    }
  }
  return newValues;
}
export { scrapeMotionValuesFromProps };