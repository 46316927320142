"use es6";

import { scaleCorrectors } from "../../projection/styles/scale-correction";
import { transformProps } from "../../render/html/utils/transform";
function isForcedMotionValue(key, {
  layout,
  layoutId
}) {
  return transformProps.has(key) || key.startsWith("origin") || (layout || layoutId !== undefined) && (!!scaleCorrectors[key] || key === "opacity");
}
export { isForcedMotionValue };