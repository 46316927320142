'use es6';

import EmailAddressValidator from '../../Email/emailValidator';
import PasswordValidator from '../../Password/passwordValidator';
import UrlValidator from '../../Url/urlValidator';

/*
  Note - the named exports of this module are the validator type strings
  that will be made available through the UIValidatedFormControl prop interface.
*/

export const email = EmailAddressValidator;
export const password = PasswordValidator;
export const url = UrlValidator;