'use es6';

// TODO Change this back when this is resolved: https://git.hubteam.com/HubSpot/asset-bender-hubspot/issues/2708
// import { bender } from 'legacy-hubspot-bender-context';
const {
  bender
} = require('legacy-hubspot-bender-context');
import PDFJS from 'pdf-js';
import { PDFPageView, EventBus } from 'pdf-js/pdf_viewer';
PDFJS.GlobalWorkerOptions.workerSrc = `${bender.staticDomainPrefix}${bender.depPathPrefixes[bender.project]}/pdf-js.worker.js`;
export { PDFJS, PDFPageView, EventBus };