"use es6";

import { time } from "../frameloop/sync-time";
import { frame, cancelFrame } from "../frameloop/frame";

/**
 * Timeout defined in ms
 */
function delay(callback, timeout) {
  const start = time.now();
  const checkElapsed = ({
    timestamp
  }) => {
    const elapsed = timestamp - start;
    if (elapsed >= timeout) {
      cancelFrame(checkElapsed);
      callback(elapsed - timeout);
    }
  };
  frame.read(checkElapsed, true);
  return () => cancelFrame(checkElapsed);
}
export { delay };