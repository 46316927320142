"use es6";

function isIdentityScale(scale) {
  return scale === undefined || scale === 1;
}
function hasScale({
  scale,
  scaleX,
  scaleY
}) {
  return !isIdentityScale(scale) || !isIdentityScale(scaleX) || !isIdentityScale(scaleY);
}
function hasTransform(values) {
  return hasScale(values) || has2DTranslate(values) || values.z || values.rotate || values.rotateX || values.rotateY || values.skewX || values.skewY;
}
function has2DTranslate(values) {
  return is2DTranslate(values.x) || is2DTranslate(values.y);
}
function is2DTranslate(value) {
  return value && value !== "0%";
}
export { has2DTranslate, hasScale, hasTransform };