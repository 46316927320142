import { Record } from 'immutable';
import * as ViewerCache from '../utils/ViewerCache';
function UUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
class ViewerRecord extends Record({
  email: null,
  vid: null,
  uuid: null,
  consentToCommunicate: null
}, 'ViewerRecord') {
  static createUUID() {
    return UUID();
  }
  static create(attrs = {}) {
    if (!attrs.uuid) {
      return new ViewerRecord(Object.assign({}, attrs, {
        uuid: ViewerRecord.createUUID()
      }));
    }
    return new ViewerRecord(attrs);
  }
  static createFromAuth(auth) {
    return ViewerRecord.create({
      email: auth.user.email
    });
  }
  static createEmpty() {
    return new ViewerRecord({
      uuid: ViewerRecord.createUUID()
    });
  }
  static getFromCache() {
    const cache = ViewerCache.get();
    if (!cache) {
      return null;
    }
    return ViewerRecord.create(cache);
  }
  static hasEmail(viewer) {
    return !!(viewer.email && viewer.email.length > 0);
  }
  static equals(first, second) {
    return !!(first.uuid && first.uuid === second.uuid || first.vid && first.vid === second.vid || first.email && first.email === second.email);
  }
}
export default ViewerRecord;