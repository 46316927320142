import I18n from 'I18n';
import { getLocale, getOptions, alternativeFractionCurrencies } from './internal/light/intlFormatters';
// @ts-expect-error untyped
import { lookup } from './internal/translateHelpers';
const getDefaultCurrencyCodeForLocale = locale => {
  var _Object$values$find;
  if (!I18n.currencySymbols['USD']) {
    console.error('It looks like currency data is not available. Are you using the new I18n loader and forgot to import currencies? See go/new-i18n');
  }

  // The default currency code for a given locale is determined by the locale definition in i18n-data and retrieved via "lookup('number.currency.format.unit')"
  // https://git.hubteam.com/HubSpot/i18n-data/tree/master/data/locales
  const unit = lookup('number.currency.format.unit', {
    locale
  });
  if (!unit) {
    // Matching CURRENCY_FORMAT 's default currency symbol of '$'
    return 'USD';
  }
  return (_Object$values$find = Object.values(I18n.currencySymbols).find(currencyData => currencyData.symbol === unit)) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.code;
};
export function formatIntlCurrency(number, options = {}) {
  var _options$currencyCode, _options$parts;
  const opts = Object.assign({
    style: 'currency',
    currency: (_options$currencyCode = options.currencyCode) !== null && _options$currencyCode !== void 0 ? _options$currencyCode : getDefaultCurrencyCodeForLocale(getLocale(options))
  }, getOptions(options));
  if (opts.currency && alternativeFractionCurrencies[opts.currency]) {
    opts.maximumFractionDigits = alternativeFractionCurrencies[opts.currency];
  }
  if (options.simplifyCurrency && number % 1 === 0) {
    opts.maximumFractionDigits = 0;
    // https://github.com/andyearnshaw/Intl.js/issues/123
    opts.minimumFractionDigits = 0;
  }
  if (options.formatNegativeValuesWithParenthesis && number < 0) {
    // In many locales, accounting format means to wrap the number with parentheses instead of appending a minus sign (eg. English)
    // In other locales, a negative value is indicated by a minus sign (eg. German)
    opts.currencySign = 'accounting';
  }
  if (typeof options.unit === 'string' && options.unit) {
    options.parts = typeof options.parts === 'object' ? Object.assign({
      currency: options.unit
    }, options.parts) : {
      currency: options.unit
    };
  }
  if (!options.currencyCode && !((_options$parts = options.parts) !== null && _options$parts !== void 0 && _options$parts.currency)) {
    const locale = getLocale(options);
    const defaultCurrencyCode = getDefaultCurrencyCodeForLocale(locale);
    const message = 'I18n: Intl formatCurrency / FormattedCurrency called with missing currencyCode.';
    console.warn(`${message} Falling back to using default currency code: "${defaultCurrencyCode}" for the provided locale: "${locale}". Please provide a currencyCode to avoid this warning.`);
    if (window.Raven) {
      window.Raven.captureException(new Error(message), {
        extra: {
          defaultCurrencyCode,
          currentLocale: locale
        }
      });
    }
  }
  const formatter = new Intl.NumberFormat(getLocale(options), opts);
  if (options.parts) {
    return formatter.formatToParts(number).reduce((acc, {
      type,
      value
    }) => `${acc}${options.parts && typeof options.parts[type] !== 'undefined' ? options.parts[type] : value}`, '');
  }
  return formatter.format(number);
}