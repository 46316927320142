import quickFetch from 'quick-fetch';
import Raven from 'raven-js';
import identity from 'transmute/identity';
// @ts-expect-error No types
import { createAction } from 'flux-actions';
import * as PresentationsV1PublicApi from 'documents-lib/js/api/PresentationsV1PublicApi';
import * as PresentationsV2PublicApi from 'documents-lib/js/api/PresentationsV2PublicApi';
import PresentationRecord from 'documents-lib/js/records/PresentationRecord';
import LegalConsentRecord from 'documents-lib/js/records/LegalConsentRecord';
import { PRESENTATION_ACTION_TYPES, GDPR_ACTION_TYPES } from 'documents-lib/js/constants/ActionTypes';
import { PUBLIC_PRESENTATION_NOT_AVAILABLE_ERROR } from 'documents-lib/js/constants/ErrorTypes';
import { ensureGdprLang } from '../utils/ensureGdprLang';
import { initTracker } from 'documents-lib/js/tracking/tracker';
const {
  PUBLIC_PRESENTATION_FETCH_SUCCEEDED,
  PUBLIC_PRESENTATION_FETCH_FAILED,
  PUBLIC_PRESENTATION_NOT_FOUND,
  PUBLIC_PRESENTATION_NOT_AVAILABLE
} = PRESENTATION_ACTION_TYPES;
const {
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED,
  PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED,
  PUBLIC_DOCUMENTS_GDPR_LANG_LOADING
} = GDPR_ACTION_TYPES;
const fetchWithEarlyRequestHook = (requestName, fallbackFetch, transformSuccess) => {
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (earlyRequest) {
    return new Promise((resolve, reject) => {
      earlyRequest.whenFinished((...args) => {
        resolve(transformSuccess(...args));
      });
      earlyRequest.onError(err => {
        if (err.status === 488) {
          // 488 can occur after a portal has done Seamless Portal Migration.
          // Perform the API fetch again with a client that includes
          // the SPM middlewares.
          return resolve(fallbackFetch());
        }
        return reject(err);
      });
    });
  }
  return fallbackFetch();
};
export const fetchPresentationSuccess = createAction(PUBLIC_PRESENTATION_FETCH_SUCCEEDED, identity);
const fetchPresentationFailed = createAction(PUBLIC_PRESENTATION_FETCH_FAILED, identity);
const fetchPresentationNotFound = createAction(PUBLIC_PRESENTATION_NOT_FOUND, identity);
const fetchPresentationNotAvailable = createAction(PUBLIC_PRESENTATION_NOT_AVAILABLE, identity);
export const fetchDocumentsLegalConsentSuccess = createAction(PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_SUCCEEDED, identity);
const fetchDocumentsLegalConsentFailed = createAction(PUBLIC_DOCUMENTS_LEGAL_CONSENT_FETCH_FAILED, identity);
export const documentsGDPRLangLoading = createAction(PUBLIC_DOCUMENTS_GDPR_LANG_LOADING, identity);
let _pendingPresentationFetch = null;
export const fetchPresentation = (presentationId, accessId) => dispatch => {
  if (_pendingPresentationFetch !== null) {
    return;
  }
  _pendingPresentationFetch = fetchWithEarlyRequestHook('public-presentation', () => PresentationsV2PublicApi.fetchPresentation(presentationId, accessId), presentation => PresentationRecord.create(presentation)).then(presentation => {
    initTracker({
      isPublic: true
    });
    dispatch(fetchPresentationSuccess(presentation));
  }, err => {
    const responseText = err.data || err.responseText;
    try {
      const responseJSON = JSON.parse(responseText);
      if (responseJSON.subCategory === PUBLIC_PRESENTATION_NOT_AVAILABLE_ERROR) {
        dispatch(fetchPresentationNotAvailable(responseJSON));
        return;
      }
      if (responseJSON.message === 'Document not found') {
        dispatch(fetchPresentationNotFound(err));
      } else {
        dispatch(fetchPresentationFailed(err));
      }
    } catch (e) {
      dispatch(fetchPresentationFailed(err));
      throw e;
    }
  }).finally(() => {
    _pendingPresentationFetch = null;
  });
};
export const fetchDownloadLink = (presentationId, accessId) => () => PresentationsV2PublicApi.fetchPresentationDownloadUrl(presentationId, accessId).then(({
  url
}) => url);
export const fetchLegalConsent = (presentationId, accessId) => dispatch => fetchWithEarlyRequestHook('public-legal-consent', () => PresentationsV1PublicApi.fetchLegalConsent(presentationId, accessId), ensureGdprLang).then(gdprSettings => dispatch(fetchDocumentsLegalConsentSuccess(LegalConsentRecord.fromJS(gdprSettings))), err => {
  const response = err.data || err.response;
  if (response && response.subCategory === PUBLIC_PRESENTATION_NOT_AVAILABLE_ERROR) {
    dispatch(fetchPresentationNotAvailable(response));
  }
  dispatch(fetchDocumentsLegalConsentFailed());
  if (err.status !== 400) {
    Raven.captureException(new Error(`Documents legal consent error ${err.status}`), err);
  }
});