import { combineReducers } from 'redux';
import Auth from 'documents-lib/js/reducers/Auth';
import View from 'documents-lib/js/reducers/View';
import LegalConsent from 'documents-lib/js/reducers/LegalConsent';
const rootReducer = combineReducers({
  auth: Auth,
  view: View,
  legalConsent: LegalConsent
});
export default rootReducer;

// This type represents the state of your root reducer,
// and can be useful when creating selectors
// Types uses of the getState argument in thunk actions