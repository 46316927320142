"use es6";

import { mapEasingToNativeEasing } from "./easing";
function animateStyle(element, valueName, keyframes, {
  delay = 0,
  duration = 300,
  repeat = 0,
  repeatType = "loop",
  ease,
  times
} = {}) {
  const keyframeOptions = {
    [valueName]: keyframes
  };
  if (times) keyframeOptions.offset = times;
  const easing = mapEasingToNativeEasing(ease);
  /**
   * If this is an easing array, apply to keyframes, not animation as a whole
   */
  if (Array.isArray(easing)) keyframeOptions.easing = easing;
  return element.animate(keyframeOptions, {
    delay,
    duration,
    easing: !Array.isArray(easing) ? easing : "linear",
    fill: "both",
    iterations: repeat + 1,
    direction: repeatType === "reverse" ? "alternate" : "normal"
  });
}
export { animateStyle };