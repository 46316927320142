hns('I18n.baseLocales', {
  "en": {
    "language": "en",
    "beta": false,
    "defaultLocale": "en-us",
    "availableLocale": [
      "en-us",
      "en-ca",
      "en-gb",
      "en-ie",
      "en-au",
      "en-nz",
      "en-in",
      "es-ar",
      "es-mx",
      "es",
      "pt-br",
      "pl",
      "da",
      "de",
      "fr",
      "it",
      "fi",
      "sv",
      "nl",
      "ja",
      "zh-cn",
      "zh-hk"
    ]
  },
  "es": {
    "language": "es",
    "beta": false,
    "defaultLocale": "es-ar",
    "availableLocale": [
      "es",
      "es-ar",
      "es-mx"
    ]
  },
  "de": {
    "language": "de",
    "beta": false,
    "defaultLocale": "de",
    "availableLocale": [
      "de"
    ]
  },
  "ja": {
    "language": "ja",
    "beta": false,
    "defaultLocale": "ja",
    "availableLocale": [
      "ja"
    ]
  },
  "fr": {
    "language": "fr",
    "beta": false,
    "defaultLocale": "fr",
    "availableLocale": [
      "fr",
      "fr-ca"
    ]
  },
  "pt-br": {
    "language": "pt",
    "beta": false,
    "defaultLocale": "pt-br",
    "availableLocale": [
      "pt-br"
    ]
  },
  "nl": {
    "language": "nl",
    "beta": false,
    "defaultLocale": "nl",
    "availableLocale": [
      "nl"
    ]
  },
  "it": {
    "language": "it",
    "beta": false,
    "defaultLocale": "it",
    "availableLocale": [
      "it"
    ]
  },
  "sv": {
    "language": "sv",
    "beta": false,
    "defaultLocale": "sv",
    "availableLocale": [
      "sv"
    ]
  },
  "fi": {
    "language": "fi",
    "beta": false,
    "defaultLocale": "fi",
    "availableLocale": [
      "fi"
    ]
  },
  "pl": {
    "language": "pl",
    "beta": false,
    "defaultLocale": "pl",
    "availableLocale": [
      "pl"
    ]
  },
  "zh-tw": {
    "language": "zh",
    "beta": false,
    "defaultLocale": "zh-tw",
    "availableLocale": [
      "zh-tw"
    ]
  },
  "no-no": {
    "language": "no",
    "beta": false,
    "defaultLocale": "no-no",
    "availableLocale": [
      "no-no"
    ]
  },
  "da": {
    "language": "da",
    "beta": false,
    "defaultLocale": "da",
    "availableLocale": [
      "da"
    ]
  }
});