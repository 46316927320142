"use es6";

import { isString } from "../utils";
const createUnitType = unit => ({
  test: v => isString(v) && v.endsWith(unit) && v.split(" ").length === 1,
  parse: parseFloat,
  transform: v => `${v}${unit}`
});
const degrees = createUnitType("deg");
const percent = createUnitType("%");
const px = createUnitType("px");
const vh = createUnitType("vh");
const vw = createUnitType("vw");
const progressPercentage = Object.assign({}, percent, {
  parse: v => percent.parse(v) / 100,
  transform: v => percent.transform(v * 100)
});
export { degrees, percent, progressPercentage, px, vh, vw };